<template>
  <div class="main-wrapper monochrome">
    <div class="breadcrumb-holder">
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="breadcrumb">
              <li>
                <router-link :to="{name:'home'}">
                  {{$static('menu.home', 'Головна')}}
                </router-link>
              </li>
              <li>Оформлення замовлення</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="default-wrapper">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="no-uppercase">{{$static('order.title', 'Оформлення замовлення:')}} </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-5 col-md-6 order-md-12">
            <div class="order-info panel">
              <div class="order-info-top">
                <h3>{{$static('order.info', 'Ваше замовлення')}}</h3>
              </div>
              <ul class="cart-list">
                <li class="cart-item" v-if="busked.length">
                  <div class="item-holder"  v-for="(item,index) in busked" :key="index">
                    <a class="remove" @click="delBasked(item, index)">
                      <img src="/images/icons/delete.svg" alt="">
                    </a>
                    <div class="item-info">
                      <router-link :to="{name: 'Item', params:{ category:  categoryURL(item), id: item.id}}">
                        <div class="image bg" :style="`background-image: url(${getItemImage(item)});`"></div>
                      </router-link>

                      <div class="right-side">
                        <div class="name">
                          <router-link :to="{name: 'Item', params:{ category:  categoryURL(item), id: item.id}}">
                            {{item.title}}
                          </router-link>
                        </div>
                        <div class="subname" v-if="item?.advanced?.text7">{{item.advanced.text7}}</div>
                        <div class="inner_holder"><span class="inner_text">{{ modName(item) }}</span></div>
                        <div class="option-box">
                          <div class="price-box">
                            <div class="price-element price-old">{{ price_old(item) }} </div>
                            <div class="price-element">{{ itemPrice(item) }}</div>
                          </div>
                          <div class="nt-spinner">
                            <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(item,-1)"></button>
                            <input type="text" class="form-control " v-model="item.count">
                            <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(item,+1)"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-else>
                  Пусто :(
                </li>
              </ul>
              <div class="order-info-bottom" v-if="busked.length">
<!--                <div class="promocode-panel">-->
<!--                  <div class="promocode-name" style="display: block;">Промокод:</div>-->
<!--                  <button class="default-btn btn-outline btn-small btn-no-icon">-->
<!--                    <span class="btn-text">Застосувати</span>-->
<!--                  </button>-->
<!--                  <input type="text" class="form-control custom-input" style="display: none;">-->
<!--                </div>-->
                <div class="total" style="border-top: 1px solid #dddedf;" >
                  <div class="total-line">
                    <span class="total-name">Загальна сума замовлення</span>
                    <span class="price-element">{{total}} <small>{{$store.getters.getSettings.symbol}}</small></span>
                  </div>
                  <div class="total-line">
                    <span class="total-name">Доставка</span>
                    <span class="price-element" v-if="total < minPrice">від 50 грн</span>
                    <span class="price-element" v-else>Безкоштовно</span>
                  </div>
                </div>
                <div class="button-holder">
                  <a v-if="isWebApp || anonymous" href="javascript:void(0)" class="default-btn btn-no-icon" @click.prevent.stop="makeOrder()">
                    <span class="btn-text">{{$static('busked.order', 'Оформити замовлення')}}</span>
                  </a>

                  <telegram-login-temp v-else-if="BOT_NAME"
                                       mode="callback"
                                       requestAccess="write"
                                       :telegram-login="BOT_NAME"
                                       @loaded='telegramLoadedCallbackFunc'
                                       @callback="yourCallbackFunction"
                  />


                  <div class="attention-apply">{{$static('modal-callback.info1', 'При кліці на кнопку я погоджуюся з')}}
                    <router-link :to="{name: 'policy'}" target="_blank">{{$static('modal-callback.info2', `політикою обробки персональних даних`)}}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-7 col-md-6 order-md-1">
            <div class="personal-data">
              <h3  class="default-h3 mt-0">{{$static('order.b1', '1. Персональні дані')}}</h3>
              <div class="card custom-card">
                <div class="form-inline">
                  <div class="form-group" :class="{'error': errors['name']}">
                    <label class="label-custom">
                      {{$static('order.name', 'Ім\'я')}}<span>*</span>
                    </label>
                    <input type="text" @focus="delError('name')" class="form-control custom-input"
                           :placeholder="$static('order.enter-name', 'Введіть ваше ім\'я')"
                           v-model="order.name"
                           name="name">
                    <p class="help-text" v-for="(err,key) in errors['name']" :key="key">{{err}}</p>
                  </div>
                  <div class="form-group">
                    <label class="label-custom">
                      {{$static('order.surname', 'Прізвище')}}<span>*</span>
                    </label>
                    <input type="text" class="form-control custom-input"
                           :placeholder="$static('order.enter-surname', 'Введіть ваше прізвище')"
                           v-model="order.surname"
                           name="second_name">
                  </div>
                  <div class="form-group" :class="{'error': errors['phone']}">
                    <label class="label-custom">
                      {{$static('order.phone', 'Телефон')}}<span>*</span>
                    </label>
                    <input type="text" @focus="delError('phone')" class="form-control custom-input"
                           :placeholder="$static('order.enter-phone', '+380998787858')" v-model="order.phone" name="phone">
                    <p class="help-text" v-for="(err,key) in errors['phone']" :key="key">{{err}}</p>
                  </div>
                  <div class="form-group">
                    <label class="label-custom">
                      {{ $static('order.email', 'Email') }}<span>*</span>
                    </label>
                    <input type="text" @focus="delError('email')" class="form-control custom-input"
                           :placeholder="$static('order.enter-email', 'Введіть ваш email')"
                           v-model="order.email"
                           name="email">
                    <p class="help-text" v-for="(err,key) in errors['email']" :key="key">{{ err }}</p>
                  </div>
                </div>
              </div>

            </div>
            <div class="delivery-data">
              <h3 class="default-h3">{{$static('order.b2', '3. Доставка')}}</h3>
              <div class="card custom-card">
                <div class="selector-body">
                  <div class="option-group">
                    <div class="empty-text" v-if="delivery.length === 0">
                      <p>{{$static('order.empty-delivery', 'У вас немає активованих способів доставки')}}</p>
                    </div>
                    <div class="option-item" v-for="(deli,index) in delivery" :key="index" @click="deliverySelected = deli.id"
                         :class="{'active': deliverySelected === deli.id}">
                      <div class='option-item-body'>
                        <p>{{$dynamic(deli.manifest, deli.manifest.title, 'title')}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="deliveryInfo && deliveryInfo.name === 'Nova Poshta'">
                  <label class="label-custom">
                    {{$static('order.city', 'Місто')}}<span>*</span>
                  </label>
                  <div class="form-row row">
                    <div class="form-group" :class="{'error': errors['delivery_city']}" style="width: 100%">
                      <SearchSelect :search="areaCity" @change="(result) => deliveryInfo.city = result"/>
                      <p class="help-text" v-for="(err,key) in errors['delivery_city']" :key="key">{{err}}</p>
                    </div>
                  </div>

                  <label class="label-custom">
                    {{$static('order.warehouse', 'Відділення')}}<span>*</span>
                  </label>
                  <div class="form-row row" :class="{'disabled': deliveryInfo.city === undefined}">
                    <div class="form-group" :class="{'error': errors['delivery_warehouse']}" style="width: 100%">
                      <SearchSelect :search="areaWarehouse" @change="(result) => deliveryInfo.warehouse = result"/>
                      <p class="help-text" v-for="(err,key) in errors['delivery_warehouse']" :key="key">{{err}}</p>
                    </div>
                  </div>
                </template>

                <template v-if="deliveryInfo && deliveryInfo.name === 'Nova Poshta Curier'">
                  <label class="label-custom">
                    {{$static('order.city', 'Місто')}}<span>*</span>
                  </label>
                  <div class="form-row row">
                    <div class="form-group" :class="{'error': errors['delivery_city']}" style="width: 100%">
                      <SearchSelect :search="areaCity" @change="(result) => deliveryInfo.city = result"/>
                      <p class="help-text" v-for="(err,key) in errors['delivery_city']" :key="key">{{err}}</p>
                    </div>
                  </div>

                  <label class="label-custom">
                    {{$static('order.addr', 'Адреса')}}<span>*</span>
                  </label>
                  <div class="form-row row" :class="{'disabled': deliveryInfo.city === undefined}">
                    <div class="form-group" :class="{'error': errors['delivery_warehouse']}" style="width: 100%">
                      <SearchSelect :search="areaStreet" @change="(result) => deliveryInfo.address = result"/>
                      <p class="help-text" v-for="(err,key) in errors['delivery_warehouse']" :key="key">{{err}}</p>
                    </div>
                  </div>

                  <label class="label-custom">
                    {{$static('order.num', 'Номер Будинку')}}<span>*</span>
                  </label>
                  <div class="form-row row">
                    <div class="form-group" :class="{'error': errors['b_number_address']}" style="width: 100%">
                      <input @focus="delError('b_number_address')" class="form-control custom-input" placeholder="..."
                             v-model="deliveryInfo.b_number" name="build_number">
                      <p class="help-text" v-for="(err,key) in errors['b_number_address']" :key="key">{{err}}</p>
                    </div>
                  </div>

                  <label class="label-custom">
                    {{$static('order.floor', 'Поверх')}}<span>*</span>
                  </label>
                  <div class="form-row row">
                    <div class="form-group" :class="{'error': errors['delivery_floor']}" style="width: 100%">
                      <input @focus="delError('delivery_floor')" class="form-control custom-input" placeholder="..."
                             v-model="deliveryInfo.floor">
                      <p class="help-text" v-for="(err,key) in errors['delivery_floor']" :key="key">{{err}}</p>
                    </div>
                  </div>

                  <label class="label-custom">
                    {{$static('order.apartment', 'Номер квартири')}}<span>*</span>
                  </label>
                  <div class="form-row row">
                    <div class="form-group" :class="{'error': errors['delivery_apartment']}" style="width: 100%">
                      <input @focus="delError('delivery_apartment')" class="form-control custom-input" placeholder="..."
                             v-model="deliveryInfo.apartment">
                      <p class="help-text" v-for="(err,key) in errors['delivery_apartment']" :key="key">{{err}}</p>
                    </div>
                  </div>
                </template>

                <template v-else-if="deliveryInfo && deliveryInfo.manifest">
                  <template v-if="deliveryInfo.manifest.city">
                    <label class="label-custom">
                      {{$static('order.city', 'Місто')}}<span>*</span>
                    </label>
                    <div class="form-row row">
                      <div class="form-group" :class="{'error': errors['delivery_city']}" style="width: 100%">
                        <input @focus="delError('delivery_city')" class="form-control custom-input" placeholder="..." v-model="deliveryInfo.city">
                        <p class="help-text" v-for="(err,key) in errors['delivery_city']" :key="key">{{err}}</p>
                      </div>
                    </div>
                  </template>

                  <template v-if="deliveryInfo.manifest.address">
                    <label class="label-custom">
                      {{$static('order.address', 'Адреса')}}<span>*</span>
                    </label>
                    <div class="form-row row">
                      <div class="form-group" :class="{'error': errors['delivery_address']}" style="width: 100%">
                        <input @focus="delError('delivery_address')" class="form-control custom-input" placeholder="..."
                               v-model="deliveryInfo.address" name="address">
                        <p class="help-text" v-for="(err,key) in errors['delivery_address']" :key="key">{{err}}</p>
                      </div>
                    </div>
                  </template>

                  <template v-if="deliveryInfo.manifest.zip">
                    <label class="label-custom">
                      {{$static('order.zip', 'Zip')}}<span>*</span>
                    </label>
                    <div class="form-row row">
                      <div class="form-group" :class="{'error': errors['delivery_zip']}" style="width: 100%">
                        <input @focus="delError('delivery_zip')" class="form-control custom-input" placeholder="..."
                               v-model="deliveryInfo.zip" name="zip">
                        <p class="help-text" v-for="(err,key) in errors['delivery_zip']" :key="key">{{err}}</p>
                      </div>
                    </div>
                  </template>

                  <template v-if="deliveryInfo.manifest.warehouse">
                    <label class="label-custom">
                      {{$static('order.warehouse', 'Відділення')}}<span>*</span>
                    </label>
                    <div class="form-row row">
                      <div class="form-group" :class="{'error': errors['delivery_warehouse']}" style="width: 100%">
                        <input @focus="delError('delivery_warehouse')" class="form-control custom-input" placeholder="..."
                               v-model="deliveryInfo.warehouse" name="warehouse">
                        <p class="help-text" v-for="(err,key) in errors['delivery_warehouse']" :key="key">{{err}}</p>
                      </div>
                    </div>
                  </template>

                  <template v-if="deliveryInfo.manifest.floor">
                    <label class="label-custom">
                      {{$static('order.floor', 'Поверх')}}<span>*</span>
                    </label>
                    <div class="form-row row">
                      <div class="form-group" :class="{'error': errors['delivery_floor']}" style="width: 100%">
                        <input @focus="delError('delivery_floor')" class="form-control custom-input" placeholder="..."
                               v-model="deliveryInfo.floor">
                        <p class="help-text" v-for="(err,key) in errors['delivery_floor']" :key="key">{{err}}</p>
                      </div>
                    </div>
                  </template>

                  <template v-if="deliveryInfo.manifest.apartment">
                    <label class="label-custom">
                      {{$static('order.apartment', 'Номер квартири')}}<span>*</span>
                    </label>
                    <div class="form-row row">
                      <div class="form-group" :class="{'error': errors['delivery_apartment']}" style="width: 100%">
                        <input @focus="delError('delivery_apartment')" class="form-control custom-input" placeholder="..."
                               v-model="deliveryInfo.apartment">
                        <p class="help-text" v-for="(err,key) in errors['delivery_apartment']" :key="key">{{err}}</p>
                      </div>
                    </div>
                  </template>

                </template>
              </div>
            </div>
            <div class="payment-data">
              <h3 class="default-h3">{{$static('order.b3', '2. Оплата')}}</h3>
              <div class="selector-body">
                <div class="option-group">
                  <div class="empty-text" v-if="payment.length === 0">
                    <p>{{$static('order.empty-pay', 'У вас немає активованих способів оплати')}}</p>
                  </div>
                  <div class="option-item" v-for="(pay,index) in payment" :key="index" @click="order.pay_type = pay.id"
                       :class="{'active': order.pay_type === pay.id}">
                    <div class='option-item-body'>
                      <p>{{$dynamic(pay.manifest, pay.manifest.title, 'title')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="comment-data">
              <div class="form-group">
                <label class="label-custom">Коментарі до замовлення</label>
                <textarea class="form-control custom-input" :placeholder="$static('order.comment', 'Введіть текст коментару')"
                          v-model="order.comment"></textarea>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { telegramLoginTemp } from 'vue3-telegram-login'
  import SearchSelect from '../components/SearchSelect';

  export default {
    name      : 'Order',
    components: {SearchSelect, telegramLoginTemp},
    data() {
      return {
        errors          : {},
        delivery        : [],
        payment         : [],
        minPrice: 1500,
        deliverySelected: undefined,
        order           : {
          name    : '',
          surname : '',
          phone   : '',
          pay_type: undefined,
          comment : ''
        },
        deliveryInfo    : undefined
      }
    },
    mounted() {
      document.title = 'Order';
      fetch('/public/plugin').then(response => response.json()).then(data => {
        let defaultPayment  = undefined;
        let defaultDelivery = undefined;

        data.forEach(x => {
          this[x.type] = x.list;
          if (x.list.length) {
            let id = x.list[0].id;
            switch (x.type) {
            case 'payment':
              defaultPayment = id;
              break;
            case 'delivery':
              defaultDelivery = id;
              break
            }
          }
        });
        if (defaultDelivery) {
          this.deliverySelected = defaultDelivery
        }
        if (defaultPayment) {
          this.order.pay_type = defaultPayment
        }
      });

      if (this.deliveryOptions.length) {
        this.delivery.type = this.deliveryOptions[0]
      }
      // if (this.payment.length) {
      //   let payDefault = this.payment.find(i => i.settings.default);
      //   if (payDefault) {
      //     this.order.pay_type = payDefault
      //   } else {
      //     this.order.pay_type = this.payment[0]
      //   }
      // }
    },
    computed  : {
      deliveryOptions() {
        return this.$store.getters.getDeliveryOptions
      },

      isWebApp() {
        let initData = localStorage.getItem('initData');
        return (initData && initData.length) || (window.Telegram && window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length)
      },
      // payment() {
      //   return this.$store.getters.getPayment
      // },
      busked() {
        return this.$store.getters.getBusked
      },
      total() {
        return this.busked.reduce((c, item) => c + (this.itemPrice(item)), 0)
      },
      settings() {
        return this.$store.getters.getSettings
      },
      buskedSettings() {
        return this.settings && this.settings.busked ? this.settings.busked : {}
      },
      anonymous() {
        return this.buskedSettings.anonymous
      },
      BOT_NAME() {
        return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
      }
    },
    methods   : {
      categoryURL(item) {
        let result = this.$store.getters.getCategories.find(i => i.id == item.category);
        if (!result) {
          return 'default'
        }
        return result.info.url
      },
      telegramLoadedCallbackFunc() {
        // console.log('script is loaded')
        this.isLoaded = true
      },
      yourCallbackFunction(user) {
        // console.log(user);
        localStorage.setItem('user_info', JSON.stringify(user));
        this.makeOrder(user);
        // fetch('/auth/login', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify(user)
        // })
        //   .then(response => response.json())
        //   .then(json => {
        //     localStorage.setItem('user_info', JSON.stringify(user));
        //     window.user_info = user;
        //     if (json.error === undefined) {
        //       if (json.init) {
        //         this.$router.push({name: 'Init'})
        //       } else {
        //         this.$router.push({name: 'Dashboard'})
        //       }
        //     }
        //   })
      },
      areaSearch(query) {
        return fetch('/public/nova/areas', {method: 'POST', body: {search: query}}).then(response => response.json()).then(data => {
          if (data.result) {
            return data.result
          }
          return []
        })
      },
      areaCity(query) {
        let search = {search: query};
        // if (this.delivery.area && !query) {
        //   search = {search: this.delivery.area.Ref, ref: false}
        // }

        return fetch('/public/nova/cities', {method: 'POST', body: JSON.stringify(search)}).then(response => response.json()).then(data => {
          if (data.result) {
            return data.result
          }
          return []
        })
      },
      areaWarehouse(query) {
        if (this.deliveryInfo.city) {
          let search = {CityRef: this.deliveryInfo.city.Ref};
          if (query) {
            search.WarehouseId = query
          }

          return fetch('/public/nova/warehouse', {method: 'POST', body: JSON.stringify(search)}).then(response => response.json()).then(
            data => {
              if (data.result) {
                return data.result
              }
              return []
            })
        }
        return new Promise(resolve => resolve([]))
      },
      areaStreet(query) {
        if (this.deliveryInfo.city) {
          let search = {CityRef: this.deliveryInfo.city.Ref};
          if (query) {
            search.FindByString = query
          }

          return fetch('/public/nova/street', {method: 'POST', body: JSON.stringify(search)}).then(response => response.json()).then(
            data => {
              if (data.result) {
                return data.result
              }
              return []
            })
        }
        return new Promise(resolve => resolve([]))
      },
      getItemImage(item) {
        if (item.preview && item.preview.includes("https://")){
          return item.preview
        }
        return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
      },

      addition_Uprice(item) {
        return item.additionUSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
      },
      addition_price(item) {
        return item.additionSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
      },
      itemPrice(item) {
        if (item.selectedMod > 0) {
          return item.advanced?.modList[item.selectedMod].price ?
              (item.advanced.modList[item.selectedMod].price + this.addition_price(item) + this.addition_Uprice(item)) * item.count : 0
        }
        return (item.price + this.addition_price(item) + this.addition_Uprice(item)) * item.count
      },
      price_old(item) {
        if (item.selectedMod > 0) {
          return item.advanced?.modList[item.selectedMod].price_old ?
              (item.advanced.modList[item.selectedMod].price_old + this.addition_price(item) + this.addition_Uprice(item))* item.count   : 0
        }
        return item.price_old + this.addition_price(item) + this.addition_Uprice(item)
      },
      modName(item) {
        return item.advanced.modList[item.selectedMod]?.name
      },
      addCount(item, i) {
        if (i + item.count) {
          item.count = Math.max(item.count + i, 1)
        }
      },
      delBasked(item, index) {
        this.$store.commit('delBasked', index)
      },
      makeOrder(user) {
        let valid = this.validate();
        if (!valid) {
          window.scrollTo(0, 0);
          return
        }

        let order = Object.assign({}, this.order, {
          delivery: this.deliveryInfo, busked: this.busked.map(i => {
            return {
              id   : i.id,
              selectedMod   : i.selectedMod,
              count: i.count,
              add  : i.additionSelect.map(i => i.id),
              u_select  : i.additionUSelect.map(i => i.id),
            }
          })
        });

        if (order.delivery.type && order.delivery.type.name) {
          order.delivery.type = order.delivery.type.name
        }

        if (order.delivery.warehouse && order.delivery.warehouse.Description) {
          order.delivery.warehouse = order.delivery.warehouse.Description
        }

        if (order.delivery.address && order.delivery.address.Description) {
          order.delivery.address = order.delivery.address.Description
        }

        if (order.delivery.city && order.delivery.city.Description) {
          order.delivery.city = order.delivery.city.Description
        }

        if (order.pay_type && order.pay_type.name) {
          order.pay_type = order.pay_type.name
        }

        window.$('#thx').addClass('is-visible');

        if (user) {
          order.user = user
        }
        let headers   = {};
        let initCache = localStorage.getItem('initData');
        if (initCache && initCache.length) {
          headers.initData = initCache;
        }

        if (window.utm){
          order.utm = window.utm
        }
        // console.log(order);
        return fetch(
          '/public/order',
          {
            method : 'POST',
            body   : JSON.stringify(order),
            headers: headers
          }
        ).then(response => response.json()).then(data => {
          if (initCache) {
            setTimeout(() => {
              window.Telegram.WebApp.close();
            }, 1000);
          }

          this.$store.commit('clearBasked');
          if (data.link) {
            setTimeout(() => {
              window.location.href = data.link;
            }, 1000);
          } else {
            setTimeout(() => {
              window.$('#thx').removeClass('is-visible');
              this.$router.push({name: 'home'});
            }, 3000);
          }

          return []
        }).catch((err) => {
          console.error(err);
          window.$('#thx').removeClass('is-visible');
          window.$('#errorModal').removeClass('is-visible');
        })

      },
      delError(key) {
        delete this.errors[key]
      },
      validate() {
        this.errors = {};
        if (this.order.name.length < 3) {
          this.errors.name = [this.$static('order.name-require-error', 'Ім\'я обов\'язкове')];
          return false
        }
        if (this.order.phone.length < 8) {
          this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
          return false
        }

        if (this.delivery.type && this.delivery.type.uid === 'NP') {
          if (this.delivery.city === undefined) {
            this.errors.delivery_city = [this.$static('order.delivery-city-error', 'Місто обов\'язкове')];
            return false
          }
          if (this.delivery.warehouse === undefined) {
            this.errors.delivery_warehouse = [this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове')];
            return false
          }
        } else {
          if (this.delivery.type && this.delivery.type.settings) {
            if (this.delivery.type.settings.city && (this.delivery.city === undefined || this.delivery.city.length < 3)) {
              this.errors.delivery_city = [this.$static('order.delivery-city-error', 'Місто обов\'язкове')];
              return false
            }
            if (this.delivery.type.settings.address && (this.delivery.address === undefined || this.delivery.address.length < 3)) {
              this.errors.delivery_address = [this.$static('order.delivery-address-error', 'Адреса обов\'язкова')];
              return false
            }
            if (this.delivery.type.settings.warehouse && (this.delivery.warehouse === undefined || this.delivery.warehouse.length < 3)) {
              this.errors.delivery_warehouse = [this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове')];
              return false
            }
          }
        }

        return true;
      }
    },
    watch     : {
      deliveryOptions(val) {
        if (val.length) {
          this.delivery.type = val[0]
        }
      },
      deliverySelected(val) {
        let x = this.delivery.find(i => i.id === val)
        if (x) {
          this.deliveryInfo = x
        }
      }
      // payment(val) {
      //   if (val.length) {
      //     let payDefault = val.find(i => i.settings.default);
      //     if (payDefault) {
      //       this.order.pay_type = payDefault
      //     } else {
      //       this.order.pay_type = val[0]
      //     }
      //   }
      // }

    }
  }
</script>

