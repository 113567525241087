<template>
  <div class="swiper special-gallery" v-if="slides">
    <SwiperSlide :slides="slides"/>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import SwiperSlide from '@/components/SwiperSlides';
export default {
  name: 'BannerSlider',
  components: {SwiperSlide},
  data() {
    return {
      plugin: undefined
    }
  },
  methods:{
    getImage(slide){
      if (slide.url){
        return `url(${slide.url})`
      }
    }
  },
  computed: {
    landing(){
     return this.$store.getters.landing
    },
    slides() {
      return this.landing?.settings?.image
    }
  },
  mounted() {
    if (!this.plugin) {
      this.plugin = new window.Swiper('.special-gallery', {
        //effect: 'fade',
        centeredSlides: true,
        loop          : true,
        spaceBetween  : 15,
        slidesPerView : 'auto',
        speed         : 700,
        //autoplay: {
        //	delay: 3000,
        //	disableOnInteraction: false,
        //},

        pagination : {
          el            : '.swiper-pagination',
          dynamicBullets: false
        },
        breakpoints: {
          320 : {
            centeredSlides: false,
            spaceBetween  : 15
          },
          575 : {
            centeredSlides: false,
            spaceBetween  : 15
          },
          768 : {

            centeredSlides: true,
            spaceBetween  : 15
          },
          1024: {
            centeredSlides: true,
            spaceBetween  : 30
          },
          1200: {
            centeredSlides: true,
            spaceBetween  : 30
          }
        }
      });
    }
  },
  beforeUnmount() {
    // this.plugin.destroy(true, true)
    // this.plugin.remove()
    this.plugin = undefined
  }
}
</script>
