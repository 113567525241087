<template>
  <div class="main-wrapper">
    <div class="breadcrumb-holder">
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="breadcrumb">
              <li>
                <router-link :to="{name:'home'}">
                  {{ $static('menu.home', 'Головна') }}
                </router-link>
              </li>
              <li>Про компанію</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-html="html" class="default-wrapper"></div>
  </div>

</template>

<script>
export default {
  name: 'About',
  data() {return {html: '', lang: ''}},
  computed: {
    isUpdate() {
      return this.$updateLang()
    }
  },
  watch   : {
    isUpdate() {
      this.getPage()
    }
  },
  methods : {
    getPage() {
      let query = '';
      let lang  = localStorage.getItem('$lang');
      if (this.lang !== lang) {
        if (lang) {
          query = '?lang=' + lang
        }
        fetch('/public/template/about' + query).then(response => response.json()).then(data => {
          let name        = 'About';
          let settings    = data && data.settings ? data.settings : {};
          let title       = settings.meta_title ? settings.meta_title : name;
          let description = settings.meta_description ? settings.meta_description : '';
          let image       = settings.image ? `${window.location.protocol}//${window.location.host}${settings.image}` : '';

          document.querySelector('title').innerHTML = title;
          document.querySelector('meta[property="og:title"]').setAttribute('content', title);
          document.querySelector('meta[name="description"]').setAttribute('content', description);
          document.querySelector('meta[property="og:description"]').setAttribute('content', description);
          document.querySelector('meta[property="og:image"]').setAttribute('content', image);

          setTimeout(() => {
            if (window.location.hash) {
              let hash = window.location.hash
              window.scrollTo({
                                top     : document.querySelector(`${hash}`).offsetTop,
                                behavior: 'smooth'
                              });
            }
          }, 100)

          this.html = data.template;
        })
      }
      this.lang = lang;
    }
  },
  mounted() {
    this.getPage();
    // (function ($) {
    //   'use strict';
    //   var slides = document.querySelectorAll(".swiper-slide");
    //   var swiper = new Swiper(".production-gallery", {
    //     centeredSlides: false,
    //     loop: true,
    //     spaceBetween: 30,
    //     speed: 700,
    //
    //     pagination: {
    //       el: ".swiper-pagination",
    //       dynamicBullets: false,
    //     },
    //     breakpoints: {
    //       320: {
    //         centeredSlides: false,
    //         spaceBetween: 15,
    //         loop: false,
    //         slidesPerView: "auto",
    //       },
    //       575: {
    //         centeredSlides: false,
    //         spaceBetween: 15,
    //         loop: false,
    //         slidesPerView: "auto",
    //       },
    //       992: {
    //         centeredSlides: false,
    //         spaceBetween: 15,
    //         slidesPerView: "auto",
    //       },
    //       1024: {
    //         spaceBetween: 30,
    //         centeredSlides: false,
    //         slidesPerView: 2,
    //       },
    //       1200: {
    //         centeredSlides: false,
    //         spaceBetween: 30,
    //         slidesPerView: 2,
    //       },
    //     },
    //
    //   });
    // })(window.$);
  }
}
</script>

<style scoped>

</style>