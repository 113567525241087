<template>
  <div class="personal-area-body">
    <div class="title-heading">
      <h1 class="h2 smallest">Налаштування доставки</h1>
    </div>

    <div class="personal-delivery">
      <form action="" class="default-form">
        <div class="personal-delivery-holder">
          <div class="card custom-card no-border">
            <div class="form-inline">
              <div class="form-group">
                <label class="label-custom">Країна <span>*</span></label>
                <input type="text" class="form-control custom-input" placeholder="Україна">
              </div>
              <div class="form-group">
                <label class="label-custom">Місто <span>*</span></label>
                <input type="text" class="form-control custom-input" placeholder="Введіть назву міста">
              </div>
              <div class="form-group">
                <label class="label-custom">Адреса <span>*</span></label>
                <div class="input-line">
                  <input type="text" class="form-control custom-input street-input" placeholder="Вулиця">
                  <input type="text" class="form-control custom-input building-input" placeholder="Будинок">
                </div>
              </div>
              <div class="form-group">
                <label class="label-custom">Спосіб доставки</label>
                <input type="text" class="form-control custom-input" value="Нова Пошта">
              </div>
            </div>
          </div>
        </div>
        <div class="button-holder">
          <button class="default-btn btn-no-icon">зберегти зміни</button>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CabinetDelivery',
  mounted() {
    window.$('.personal_area-sidebar').removeClass('open');
    window.$('body').removeClass('no-scroll');
    window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
  }
}
</script>

<style scoped>

</style>