<template>
  <header class="gray">
    <div class="header-holder">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="header-menu">
              <ul>
                <li>
                  <router-link :to="{name:'home'}" :active-class="'active'">
                    <span class="text">{{$static('menu.home', 'Головна')}}</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'full-catalog'}" :active-class="'active'">
                    <span class="text">{{$static('menu.catalog', 'Каталог')}}</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'delivery'}" :active-class="'active'">
                    <span class="text">Доставка</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'about'}" :active-class="'active'">
                    <span class="text">Про нас</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </div>
  </header>
</template>

<script>
  export default {
    name    : 'MenuHeader',
    mounted() {},
    methods : {

    },
    data() {
      return {
        init   : false
      }
    },
    computed: {
      settings() {
        return this.$store.getters.getSettings
      },
      current() {
        return this.$store.getters.getLang
      },

      // lang() {
      //   return this.settings ? this.settings.lang : this.settings
      // },
    },
    watch   : {
      // lang(val) {
      //   if (val) {
      //     this.fetchOrigin().then(() => {
      //       const lang = localStorage.getItem('$lang');
      //       if (lang) {
      //         let x = val.find(i => i.short === lang);
      //         if (x) {
      //           this.current = x;
      //           this.fetchLang()
      //         }
      //       }
      //     })
      //   }
      // },
      settings(val) {
        if (val && val.favicon) {
          let link = document.querySelector('link[rel~=\'icon\']');
          if (link) {
            link.href = val.favicon
          }
        }
      }
    }
  }
</script>