<template>
<div class="main-wrapper">
  <div class="breadcrumb-holder">
    <div class="container">
      <div class="row">
        <div class="col">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{name:'home'}">
                {{$static('menu.home', 'Головна')}}
              </router-link>
            </li>
            <li>{{$static('menu.catalog', 'Каталог')}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <BannerSlider/>
  <div class="catalog-wrapper section">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="sorting-panel" v-if="tags && tags.length">
            <h2>Усі товари</h2>
            <div class="sort-list">
              <div class="sorting-checkbox">
                <input id="all" type="checkbox" v-model="all_tags" value="1">
                <label for="all">
                  <span class="text">{{ $static('options.all', 'Усі') }}</span>
                </label>
              </div>

              <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
                <input :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                <label :for="op.id">
                  <span class="pick" v-if="op.image" :style="getImage(op)"></span>
                  <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                  <span class="text">{{ $dynamic(op, op.name, 'name') }}</span>
                </label>
              </div>
            </div>
          </div>
          <ul class="catalog-grid">

            <li class="catalog-item" v-for="(item,index) in items" :key="index">
              <div class="item-holder">
                <div class="product-label label-new" v-if="isHot(item)">
                  <span class="label-text">New</span>
                </div>
                <div class="product-label label-sale" v-if="item.price_old">
                  <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
                </div>
                <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}"
                             class="image bg" :style="`background-image: url(${getItemImage(item)});`">
                </router-link>
                <div class="item-content">
                  <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}">
                    <h3 class="product-name">{{ $dynamic(item, item.title, 'title') }}</h3>

                  </router-link>
                </div>
                <div class="options">
                  <div class="price-box">
                    <div class="price-element price-old" v-if="item.price_old">
                      {{ $filters.money(item.price_old) }} {{ $store.getters.getSettings.symbol }}
                    </div>
                    <div class="price-element"> {{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>

                  </div>
                  <div class="right-options">
                    <router-link :to="{name: 'Item', params:{ category:  categoryURL(item), id: item.id}}"
                                 class="default-btn btn-small">
                      <span class="btn-text">{{ $static('catalog.info', 'Детальніше') }}</span>
                      <span class="icon icon-bag-outline"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <li class="catalog-item more-catalog-item" @click="getMore" v-if="more">
              <div class="item-holder d-flex align-items-center text-center justify-content-center h-100">
                <div class="more-content">
                  <div class="text-wrapper">
                    {{ $static('catalog.more_load', 'Завантажити ще') }}

                  </div>
                  <div class="icons-holder">
                    <img src="/images/icons/load.svg">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="quote-box ceo-text" v-if="seoText">
    <div class="container" v-html="seoText">

    </div>
  </div>
</div>
</template>

<script>
import BannerSlider from '@/components/BannerSlider';
import axios from 'axios';

export default {
  name: 'FullCatalog',
  components: {BannerSlider},
  data(){return{
    items       : [],
    page        : 1,
    perPage     : 11,
    more        : true,
    optionSelect: [],
  }},
  computed:{
    tags() {
      return this.$store.getters.getTags
    },
    settings() {
      return this.$store.getters.getSettings
    },
    seoText(){
      return this.settings?.catalogSeo
    },

    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    }
  },
  mounted() {
    this.getItems(true)
  },
  methods:{
    getMore() {
      this.page += 1;
      this.getItems()
    },
    getImage(item) {
      return `background-image: url(${item.image});`
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    getDiscount(item) {
      return item.preview ? (1 - Math.floor(Math.max(item.price / item.price_old, 0)*100)/100) * 100 | 0 : ''
    },
    isHot(item) {
      return item.advanced && item.advanced.hot
    },
    categoryURL(item) {
      // return {id: 1}
      // console.log(this.$store.getters.getCategories, item.category);
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    getItems(clear) {
      if (this.load) {
        return
      }
      this.load  = true;
      let filter = {perPage: this.perPage, page: this.page};
      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).map(x => x ? x.id : x);
      }
      axios.post('/public/item/filter', {filter: filter}).then(response => response.data).then(data => {
        this.load = false;
        if (clear) {
          this.items = data.result
        } else {
          this.items.push(...data.result);
        }
        this.page  = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
      }).catch(() => {
        this.load = false;
      })
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
  },
  watch:{
    optionSelect() {
      this.getItems(true)
    }
  }
}
</script>

