<template>
  <div class="main-wrapper">
    <div class="breadcrumb-holder">
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="breadcrumb">
              <li>
                <router-link :to="{name:'home'}">
                  {{$static('menu.home', 'Головна')}}
                </router-link>
              </li>
              <li>{{$static('menu.contacts', 'Контакти')}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="default-wrapper contacts-wrapper section">
      <div class="half-image bg-cover" style="background-image: url(/images/contacts.webp);"></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-7">
            <h1 class="no-uppercase">Контакти</h1>
            <div class="contacts-holder">
              <div class="contacts-rows">
                <div class="contact-column">
                  <div class="times">
                    <p>Оформлення замовлень на сайті</p>
                    <div class="time">
                      <span class="icon icon-clock"></span>
                      <span class="value">з 9:00 до 21:00</span>
                    </div>
                  </div>
                  <div class="large-phone">
                    <small>Усі дзвінки у межах <br/>України безкоштовно</small>
                    <a href="tel:0800200010">0 800 20 00 10</a>
                  </div>
                  <ul class="phones">
                    <li>
                      <a href="tel:+380504780101" class="default-btn btn-with-icon-alt btn-white-outline">
                        <span class="icon icon-phone-filled"></span>
                        <span class="btn-text">+380 (50) 478 01 01</span>
                      </a>
                    </li>
                    <li>
                      <a href="tel:+380734780101" class="default-btn btn-with-icon-alt btn-white-outline">
                        <span class="icon icon-phone-filled"></span>
                        <span class="btn-text">+380 (73) 478 01 01</span>
                      </a>
                    </li>
                    <li>
                      <a href="tel:+380684780101" class="default-btn btn-with-icon-alt btn-white-outline">
                        <span class="icon icon-phone-filled"></span>
                        <span class="btn-text">+380 (68) 478 01 01</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="contact-column">
                  <div class="times">
                    <p>Консультація і замовлення в чатах:</p>
                    <div class="time">
                      <span class="icon icon-clock"></span>
                      <span class="value">з 9:00 до 21:00</span>
                    </div>
                  </div>

                  <ul class="phones social-btns">
                    <li>
                      <a href="https://t.me/PharmaluxeCare_bot"  class="default-btn btn-with-icon-alt btn-gray no-uppercase">
                        <img src="/images/icons/telegram-color.svg" alt="">
                        <span class="btn-text">Написати в Telegram</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://wa.me/380504780101"  class="default-btn btn-with-icon-alt btn-gray no-uppercase">
                        <img src="/images/icons/whatsapp-color.svg" alt="">
                        <span class="btn-text">Написати в Whatsapp</span>
                      </a>
                    </li>
                  </ul>
                  <div class="email-box">
                    <h4>З питань співпраці:</h4>

                    <a href="mailto:pharmaluxe.care@gmail.com"  class="default-btn btn-with-icon-alt btn-white-outline no-uppercase">
                      <span class="icon icon-envelope"></span>
                      <span class="btn-text">Написати на email</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="business-contacts">
                <div class="contacts-label">Контакти для бізнес-клієнтів</div>
                <ul>
                  <li>
                    <a href="tel:+380504028118">
                      <span class="icon icon-phone-filled"></span>
                      <span class="text">+38 (050)-402-81-18</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:pharmaluxe.sales@gmail.com">
                      <span class="icon icon-envelope"></span>
                      <span class="text">pharmaluxe.sales@gmail.com</span>
                    </a>
                  </li>
                </ul>
              </div>
              <address><span class="content-bold">ТОВ “ФАРМА ЛЮКС УКРАЇНА”,</span> <br/>  Україна, 61001, Харківська обл., м.Харків, <br/> вул.Плеханівська, д. 92-а, офіс 303</address>
              <div class="just-address">
                <div class="contacts-label">Юридична адреса:</div>
                <address>Україна, 61031, Харківська обл., місто Харків, <br/>вул. Ромашкіна, будинок 8 А, квартира 101</address>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>

</style>