<template>
  <div class="frame-modal-wrapper quick-cart" id="quick_cart">
    <div class="modal-wrapper-holder">

      <div class="frame-modal-header">
        <h3>{{$static('busked.title', 'Ваша корзина')}}</h3>
        <a href="javascript:void(0);" class="close-cart">
          <span class="icon icon-close visible-device"></span>
          <span class="icon icon-long-arrow-left hidden-device"></span>
        </a>
      </div>
      <div class="frame-modal-body" v-if="busked.length">
        <ul class="frame-modal-list">

          <li class="cart-item" v-for="(item,index) in busked" :key="index">
            <div class="item-holder">
              <a class="remove">
                <img src="/images/icons/delete.svg" alt="" @click.prevent="delBasked(item,index)">
              </a>
              <div class="item-info">
                <div class="image bg" :style="`background-image: url(${getItemImage(item)});`">

                </div>
                <div class="right-side">
                  <div class="name">
                    <router-link :to="{name: 'Item', params:{ category:  categoryURL(item), id: item.id}}">
                      {{item.title}}
                    </router-link>
                  </div>
                  <div class="subname" v-if="item?.advanced?.text7">{{item.advanced.text7}}</div>
                  <div class="inner_holder"><span class="inner_text">{{modName(item)}}</span></div>
                  <div class="option-box">
                    <div class="price-box">
                      <div class="price-element price-old">{{ price_old(item) }}</div>
                      <div class="price-element">{{ itemPrice(item) }}</div>
                    </div>
                    <div class="nt-spinner">
                      <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(item,-1)"></button>
                      <input type="text" class="form-control " v-model="item.count">
                      <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(item,+1)"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
      <div class="frame-modal-bottom">
<!--        <div class="promocode-panel">-->
<!--          <div class="promocode-name" style="display: block;">Промокод:</div>-->
<!--          <button class="default-btn btn-outline btn-small btn-no-icon">-->
<!--            <span class="btn-text">Застосувати</span>-->
<!--          </button>-->
<!--          <input type="text" class="form-control custom-input" style="display: none;">-->
<!--        </div>-->
        <div class="total">
          <div class="total-line">
            <span class="total-name">Загальна сума замовлення</span>
            <span class="price-element">{{total}} <small>{{$store.getters.getSettings.symbol}}</small></span>
          </div>
          <div class="total-line">
            <span class="total-name">Доставка</span>
            <span class="price-element" v-if="total < minPrice">від 50 грн</span>
            <span class="price-element" v-else>Безкоштовно</span>
          </div>
        </div>
        <div class="button-holder">
          <button class="default-btn btn-dark btn-no-icon trigger-to-order" @click="orderPage">
            <span class="btn-text">{{$static('busked.order', 'Оформити замовлення')}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name    : 'Busked',
    data(){
      return{
        minPrice: 1500
      }
    },
    mounted() {
      (function ($) {
        'use strict';

        $('#quick_card_trigger a').on('click', function () {
          $('body').addClass('no-scroll');
          $('#quick_cart').addClass('opened');
          $('.frame-modal-wrapper-bg').addClass('is-visible');
          return false;
        });

        $('.frame-modal-wrapper-bg').on('click', function () {
          $(this).removeClass('is-visible');
          $('.overlay-menu').removeClass('active');
          $('body').removeClass('no-scroll');

          $('#quick_cart').removeClass('opened');
          return false;
        });

        $('.frame-modal-wrapper .close-cart').on('click', function () {
          $('.frame-modal-wrapper').removeClass('opened');
          $('body').removeClass('no-scroll');
          $('.frame-modal-wrapper-bg').removeClass('is-visible');
          return false;
        });

        $('.frame-back-link .back-link').on('click', function () {
          $('.frame-modal-wrapper').removeClass('opened');
          $('body').removeClass('no-scroll');
          $('.frame-modal-wrapper-bg').removeClass('is-visible');
          return false;
        });


      })(window.$);
    },
    beforeUnmount() {
      (function ($) {
        'use strict';
        $('.frame-modal-wrapper').removeClass('opened');
        $('body').removeClass('no-scroll');
        $('.frame-modal-wrapper-bg').removeClass('is-visible');

      })(window.$);
    },
    methods : {
      orderPage() {
        this.$router.push({name: "Order"});
      },
      getItemImage(item) {
        return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
      },
      addition_Uprice(item) {
        return item.additionUSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
      },
      addition_price(item) {
        return item.additionSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
      },
      itemPrice(item) {
        if (item.selectedMod > 0) {
          return item.advanced?.modList[item.selectedMod].price ?
              (item.advanced.modList[item.selectedMod].price + this.addition_price(item) + this.addition_Uprice(item)) * item.count : 0
        }
        return (item.price + this.addition_price(item) + this.addition_Uprice(item)) * item.count
      },
      price_old(item) {
        if (item.selectedMod > 0) {
          return item.advanced?.modList[item.selectedMod].price_old ?
              (item.advanced.modList[item.selectedMod].price_old + this.addition_price(item) + this.addition_Uprice(item)) * item.count : 0
        }
        return item.price_old + this.addition_price(item) + this.addition_Uprice(item)
      },
      modName(item) {
        return item.advanced.modList[item.selectedMod]?.name
      },
      delBasked(item, index) {
        this.$store.commit('delBasked', index)
      },
      addCount(item, i) {
        if (i + item.count) {
          item.count = Math.max(item.count + i, 1)
        }
      },
      categoryURL(item) {
        let result = this.$store.getters.getCategories.find(i => i.id == item.category);
        if (!result) {
          return 'default'
        }
        return result.info.url
      }
    },
    computed: {
      busked() {
        return this.$store.getters.getBusked
      },
      total() {
        return this.busked.reduce((c, item) => c + (this.itemPrice(item)), 0)
      }
    }
  }
</script>

<style scoped>

</style>