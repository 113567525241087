<template>
  <div class="main-wrapper" :class="item?.advanced?.text5">
    <div class="product-info-wrapper">
      <div class="breadcrumb-holder">
        <div class="container">
          <div class="row">
            <div class="col">
              <ul class="breadcrumb">
                <li>
                  <router-link :to="{name:'home'}">
                    {{ $static('menu.home', 'Головна') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'full-catalog'}">
                    {{ $static('menu.catalog', 'Каталог') }}
                  </router-link>
                </li>
                <li v-if="item">{{$dynamic(item, item.title, 'title')}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="product-header" v-if="item">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="product-header-holder">
                <div class="product-header-info">
                  <div class="availability-holder d-none">
                    <div class="availability not-availability" v-if="!isStock">
                      <span>{{ $static('item.nema', 'Нема в наявності') }} </span>
                    </div>
                    <div class="availability in-stock" v-else>
                      <span>{{ $static('item.est', 'В наявності ') }}</span>
                    </div>
                  </div>
                  <h1>{{ $dynamic(item, item.title, 'title') }}</h1>

                  <div class="custom-tabs">
                    <ul class="nav nav-tabs " id="product-tabs" role="tablist">
                      <li class="nav-item" v-if="item.description && item.description.length > 7">
                        <a class="nav-link active" data-toggle="pill" href="#tab_1" role="tab" aria-selected="true">
                          {{$static('item.op1', 'Опис')}}
                        </a>
                      </li>
                      <li class="nav-item" v-if="item.advanced?.text1?.length">
                        <a class="nav-link" data-toggle="pill" href="#tab_2" role="tab" aria-selected="false">
                          Користь
                        </a>
                      </li>
                      <li class="nav-item" v-if="item.advanced?.text2?.length">
                        <a class="nav-link" data-toggle="tab" href="#tab_3" role="tab" aria-selected="false">
                          інгредієнти
                        </a>
                      </li>
                      <li class="nav-item" v-if="item.advanced?.text3?.length">
                        <a class="nav-link" data-toggle="tab" href="#tab_4" role="tab" aria-selected="false">
                          спосіб застосування
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="tab_1" role="tabpanel"
                           v-if="item.description && item.description.length > 7">
                        <div class="panel-body">
                          <div class="text" v-html="$dynamic(item, item.description, 'description')">
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tab_2" role="tabpanel" v-if="item.advanced?.text1?.length">
                        <div class="panel-body">
                          <div class="text" v-html="$dynamic(item, item.advanced.text1, 'text1')">
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tab_3" role="tabpanel" v-if="item.advanced?.text2?.length">
                        <div class="panel-body">
                          <div class="text" v-html="$dynamic(item, item.advanced.text2, 'text2')">
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tab_4" role="tabpanel" v-if="item.advanced?.text3?.length">
                        <div class="panel-body">
                          <div class="text" v-html="$dynamic(item, item.advanced.text3, 'text3')">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="quick-info" v-if="modSelected">
                    <div class="left-content" >
                      <div class="image">
                        <img src="/images/icons/tablet.svg" alt="">
                      </div>
                      <div class="text">
                        <small>В упаковці:</small>
                        <p>{{ modSelected.name }}</p>
                        <div class="label"><span>{{modSelected.gram}}</span></div>
                      </div>
                    </div>
                    <div class="price-box">
                      <div class="price-element price-old" v-if="item.price_old"> {{ $filters.money(price_old) }}
                        {{ $store.getters.getSettings.symbol }}
                      </div>
                      <div class="price-element"> {{ $filters.money(price) }} {{ $store.getters.getSettings.symbol }}</div>
                    </div>
                  </div>

                  <div class="volume-group" v-if="modList && modList.length > 1">
                    <div class="volume-title">
                      <span>Об'єм</span>
                    </div>
                    <div class="volume-list">
                      <div class="volume-radio" v-for="(mod, index) in modList" :key="index">
                        <input :id="'add'+index" type="radio" name="volume" v-model="order.selectedMod" :value="index">
                        <label :for="'add'+index">
                          <span class="image" v-if="mod.image" :style="{backgroundImage: modImage(mod.image)}"></span>
                          <span class="text">
                            <span class="volume-name">{{ mod.name }}</span>
                            <span class="volume-price">
                              <span class="price-element price-old" v-if="mod.price_old">{{mod.price_old}} грн</span>
                              <span class="price-element">{{ modPrice(mod,index) }} грн</span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="options d-flex">
                    <div class="nt-spinner">
                      <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(-1)"></button>
                      <input type="text" class="form-control " v-model="order.count">
                      <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(+1)"></button>
                    </div>
                    <div class="button-holder">
                      <button class="default-btn quick_cart_trigger" @click="buy">
                        <span class="btn-text">{{ $static('item.buy', 'До кошика') }}</span>
                        <span class="icon icon-bag-outline"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="product-header-imgs" >
                  <a class="large-label product-label label-plants" v-if="item?.advanced?.text6" href="#ingredient_plants_modal" data-toggle="modal">
                    <span class="label-text" v-html="item?.advanced?.text6"></span>
                  </a>
                  <a class="large-label product-label label-sale" v-else >
                    <span class="label-text">-{{getDiscount(item)}}%</span>
                  </a>

                  <div class="single-image">
                    <div class="image bg" :style="`background-image: url(${getItemImage(item)});`"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-support-info">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="holder">
                <div class="item">
                  <div class="image">
                    <img src="/images/icons/delivery.svg" alt="">
                  </div>
                  <p>Безкоштовна доставка <br/>від 1500 грн</p>
                </div>
                <div class="item">
                  <div class="image">
                    <img src="/images/icons/speed.svg" alt="">
                  </div>
                  <p>Відправлення в день оформлення <br/>замовлення до 16:00</p>
                </div>
                <div class="item">
                  <div class="image">
                    <img src="/images/icons/warranty.svg" alt="">
                  </div>
                  <p>Гарантія повернення <br/>грошових коштів</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="product-catalog section" v-if="upsaleItems && upsaleItems.length">
        <div class="container">
          <div class="row">
            <div class="col">
              <h2 class="text-center">Ідеально поєднуються з <br/><span>{{item.title}}</span></h2>

            </div>
          </div>
        </div>
        <div class="container product-container">
          <ul class="catalog-grid">
            <li class="minimal-catalog-item catalog-item" v-for="(item,index) in upsaleItems" :key="index">
              <div class="item-holder">
                <div class="product-label label-new" v-if="isHot(item)">
                  <span class="label-text">New</span>
                </div>
                <div class="product-label label-sale" v-if="item.price_old">
                  <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
                </div>

                <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}" class="image bg-cover"
                             @click.prevent.stop="" :style="`background-image: url(${getItemImage(item)});`"></router-link>
                <div class="item-content">
                  <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}">
                    <h3 class="product-name">{{ $dynamic(item, item.title, 'title') }}</h3>
                  </router-link>

                  <div class="product-desc" v-if="item?.advanced?.text7">{{ $dynamic(item, item.advanced.text7, 'text7') }}</div>
                </div>
                <div class="options">
                  <div class="price-box">
                    <div class="price-element price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                      {{ $store.getters.getSettings.symbol }}
                    </div>
                    <div class="price-element"> {{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>

                  </div>
                  <div class="right-options">
                    <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}" class="default-btn ">
                      <span class="btn-text">{{ $static('catalog.info', 'Детальніше') }}</span>
                      <span class="icon icon-bag-outline"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="item?.advanced?.text4?.length" v-html="$dynamic(item, item.advanced.text4, 'text4')"></div>

  </div>
  <div class="product-container">
    <div class="product-other" v-if="item">
      <ItemOption v-for="(option,index) in options" :key="index" :option_key="index" :option="option" :settings="optionSettings"
                  @select="selectOption"/>

      <Teleport to="body">
        <div class="fixed-quick-panel">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="quick-panel-holder">
                  <div class="item-info">
                    <div class="image bg" :style="`background-image: url(${getItemImage(item)});`"></div>
                    <div class="right-side">
                      <div class="name">{{ $dynamic(item, item.title, 'title') }}</div>
                      <div class="subname">{{ $dynamic(item, item.advanced.text7, 'text7') }}</div>
                    </div>
                  </div>
                  <div class="options">
                    <div class="price-box">
                      <div class="price-old" v-if="item.price_old"> {{ (price_old) }} {{ $store.getters.getSettings.symbol }}</div>
                      <div class="price-element"> {{ $filters.money(price) }} {{ $store.getters.getSettings.symbol }}</div>
                    </div>
                    <div class="option-box">
                      <div class="nt-spinner">
                        <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(-1)"></button>
                        <input type="text" class="form-control " v-model="order.count">
                        <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(+1)"></button>
                      </div>
                      <div class="button-holder">
                        <button class="default-btn quick_cart_trigger" @click="buy">
                          <span class="btn-text">{{ $static('item.buy', 'До кошика') }}</span>
                          <span class="icon icon-bag-outline"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Teleport>
    </div>


  </div>

</template>

<script>
import axios from 'axios'
import ItemOption from './ItemOption';

export default {
  name      : 'Item',
  components: {ItemOption},
  props     : ['category', 'id'],
  data() {
    return {
      item       : undefined,
      upsaleItems: [],
      order      : {
        count         : 1,
        selectedMod   : 0,
        additionSelect: [],
        additionUSelect: []
      }
    }
  },
  mounted() {
    this.getItem();
    window.BuyNow = this.buy

    window.scrollTo(0, 0);

    window.$('.product-images a').fancybox();

    // window.$(`a[href="/catalog/${this.category}"]`).addClass('active');

    window.$('.frame-modal-wrapper-bg').on('click', function () {
      window.$(this).removeClass('is-visible');
      window.$('body').removeClass('no-scroll');

      window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
      window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
      return false;
    });
  },
  methods : {
    modPrice(mod,index){
      if (mod.price !== undefined){
        return mod.price
      }
      if (index === 0){
        return this.item.price
      }
    },
    modImage(image){
      return `url(${image})`
    },
    fancyClick(e) {
      if (window.change) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    mouseDownHandlerTarget(e, targetClass) {
      let target = e.target;
      while (!target.className.includes(targetClass)) {
        target = target.parentElement
      }
      window.ele = target;
      window.mouseDownHandler(e)
    },
    addCount(i) {
      if (i + this.order.count) {
        this.order.count = Math.max(this.order.count + i, 1)
      }
    },
    isHot(item) {
      return item.advanced && item.advanced.hot
    },
    selectOption({item, status}) {
      if (!this.order.additionSelect) {
        this.order.additionSelect = []
      }
      if (status) {
        this.order.additionSelect.push(item)
      } else {
        let idx = this.order.additionSelect.findIndex(x => x.id == item.id);
        if (idx > -1) {
          this.order.additionSelect.splice(idx, 1)
        }
      }
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    getDiscount(item) {
      return item.preview ? (1 - Math.floor(Math.max(item.price / item.price_old, 0)*100)/100) * 100 | 0 : ''
    },
    categoryURL(item) {
      let category = this.categories.find(x => x.id == item.category);
      return category ? category.info.url : ''
    },
    buy() {
      let idx = -1;
      idx     = this.basked.findIndex(i => i.id === this.item.id);
      if (idx > -1) {
        // this.basked[idx].count += this.order.count;
        this.$store.commit(
            'replaceBuskedIndex',
            {index: idx, value: JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))}
        );
      } else {
        // console.log(JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item, {sizeSelect: this.sizeSelected}))));
        this.$store.commit(
            'addToBasked',
            JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))
        );
      }
      window.$('body').addClass('no-scroll');
      window.$('#quick_cart').addClass('opened');
      window.$('.frame-modal-wrapper-bg').addClass('is-visible');
    },
    getURLImage(image) {
      if (image && image.includes('https://')) {
        return image
      }
      return `${window.location.protocol}//${window.location.host}${image}`
    },

    getItem() {
      return axios.get(`/public/item/${this.id}`)
          .then(response => response.data)
          .then(data => {
            if (data.result) {
              this.item = data.result;
              this.order.selectedMod = 0;
              if (this.item?.advanced?.upsale) {
                this.getUpsaleItems(this.item.advanced.upsale)
              }else{
                this.getUpsaleItems()
              }
            }
            if (data.error === 'not found') {
              this.$router.push({name: 'NotFound'});
            }
            return
          }).then(() => {
            if (this.item && this.item.advanced) {
              let title = this.item.advanced.meta_title ? this.item.advanced.meta_title : this.$dynamic(
                  this.item,
                  this.item.title,
                  'title'
              );

              document.querySelector('title').innerText = title;
              document.querySelector('meta[property="og:title"]').setAttribute('content', title);

              let description = this.item.advanced.meta_description;
              document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
              document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');

              let image = this.item.preview ? `${window.location.protocol}//${window.location.host}${this.item.preview}` : '';
              document.querySelector('meta[property="og:image"]').setAttribute('content', image)
            }
          }).catch(error => {

          })
    },
    getUpsaleItems(upsale) {
      if (upsale && upsale.length) {
        axios.post(`/public/item`, {list: upsale.map(i => i.id)})
            .then(response => response.data)
            .then(data => {
              if (data.result) {
                this.upsaleItems = data.result
              }
            })
      }else{
        this.upsaleItems = []
      }
    }
  },
  computed: {
    modList() {
      return this.item?.advanced?.modList
    },
    modSelected() {
      return this.modList[this.order.selectedMod]
    },
    addition_Uprice() {
      return this.order.additionUSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
    },
    addition_price() {
      return this.order.additionSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
    },
    price() {
      if (this.order.selectedMod > 0) {
        return this.item?.advanced?.modList[this.order.selectedMod].price ?
            (this.item.advanced.modList[this.order.selectedMod].price + this.addition_price + this.addition_Uprice)* this.order.count : 0
      }
      return (this.item.price + this.addition_price + this.addition_Uprice) * this.order.count
    },
    price_old() {
      if (this.order.selectedMod > 0) {
        return this.item?.advanced?.modList[this.order.selectedMod].price_old ?
            this.item.advanced.modList[this.order.selectedMod].price_old + this.addition_price + this.addition_Uprice : 0
      }
      return this.item.price_old + this.addition_price + this.addition_Uprice
    },
    categories() {
      return this.$store.getters.getCategories
    },
    settings() {
      return this.$store.getters.getSettings
    },
    options() {
      return this.item && this.item.advanced && this.item.advanced.options ? this.item.advanced.options : []
    },
    optionSettings() {
      return this.$store.getters.getOptionSettings ? this.$store.getters.getOptionSettings : []
    },

    basked() {
      return this.$store.state.busked
    },
    isHotItem() {
      return this.item && this.item.advanced && this.item.advanced.hot
    },
    isStock() {
      return this.item && this.item.stock
    }
  },
  watch   : {
    id() {
      this.getItem();
      this.order = {
        count         : 1,
        selectedMod   : 0,
        additionSelect: [],
        additionUSelect: []
      };

      // window.$(`.category-panel-holder a.active`).removeClass('active');
      // window.$(`a[href="/catalog/${this.category}"]`).addClass('active');

      window.scrollTo(0, 0);

      window.$('.product-images a').fancybox();

      window.$('.frame-modal-wrapper-bg').on('click', function () {
        window.$(this).removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
        return false;
      });
    }
  }

}
</script>

