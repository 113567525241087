<template>
  <div class="main-wrapper">
    <div class="breadcrumb-holder">
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="breadcrumb">
              <li>
                <router-link :to="{name:'home'}">
                  {{$static('menu.home', 'Головна')}}
                </router-link>
              </li>
              <li>
                <router-link :to="{name:'full-catalog'}">
                  {{$static('menu.catalog', 'Каталог')}}
                </router-link>
              </li>
              <li>{{$dynamic(categoryObject, categoryName, 'name')}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper special-gallery">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="text">
              <a href="#global-detox-program" class="default-btn btn-small light-btn " data-toggle="modal">
                <span class="btn-text">Детальніше</span>
                <span class="icon icon-caret-right"></span>
              </a>
            </div>
            <div  class="image bg-cover hidden-device" style="background-image: url(/images/specials/special2.jpg);"></div>
            <div  class="image bg-cover visible-device" style="background-image: url(/images/specials/special2-mob.jpg);"></div>
          </div>
        </div>


        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="text">
              <a href="#global-detox-program" class="default-btn btn-small light-btn " data-toggle="modal">
                <span class="btn-text">Детальніше</span>
                <span class="icon icon-caret-right"></span>
              </a>
            </div>
            <div  class="image bg-cover hidden-device" style="background-image: url(/images/specials/special3.jpg);"></div>
            <div  class="image bg-cover visible-device" style="background-image: url(/images/specials/special3-mob.jpg);"></div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="text">
              <a href="#global-detox-program" class="default-btn btn-small light-btn " data-toggle="modal">
                <span class="btn-text">Детальніше</span>
                <span class="icon icon-caret-right"></span>
              </a>
            </div>
            <div  class="image bg-cover hidden-device" style="background-image: url(/images/specials/special1.jpg);"></div>
            <div  class="image bg-cover visible-device" style="background-image: url(/images/specials/special1-mob.jpg);"></div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
  <div class="catalog-wrapper section">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2>{{$dynamic(categoryObject, categoryName, 'name')}}</h2>
          <div class="sorting-panel" v-if="tags && tags.length">
            <div class="custom-label">
              {{$static('catalog.title', 'Оберіть своє:')}}
            </div>
            <div class="sort-list">
              <div class="sorting-checkbox">
                <input id="all" type="checkbox" v-model="all_tags" value="1">
                <label for="all">
                  <span class="text">{{$static('options.all', 'Усі')}}</span>
                </label>
              </div>

              <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
                <input :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                <label :for="op.id">
                  <span class="pick" v-if="op.image" :style="getImage(op)"></span>
                  <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                  <span class="text">
              <span class="custom-option-name" v-if="op.name">{{$dynamic(op, op.name, 'name')}}</span>
              <span class="custom-option-price">
                <span class="price" v-if="op.price"> {{$filters.money(op.price)}} {{$store.getters.getSettings.symbol}}</span>
                <span class="price price-old" v-if="op.price_old"> {{$filters.money(op.price_old)}} {{$store.getters.getSettings.symbol}}</span>
              </span>
            </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container catalog-container">
      <div class="row">
        <div class="col">
          <ul class="catalog-grid">
            <li class="catalog-item" v-for="(item,index) in items" :key="index">
              <div class="item-holder">
                <div class="product-label label-new" v-if="isHot(item)">
                  <span class="label-text">New</span>
                </div>
                <div class="product-label label-sale" v-if="item.price_old">
                  <div class="tile_label tile_sale">-{{getDiscount(item)}}%</div>
                </div>
                <router-link :to="{name: 'Item', params:{ category: categoryObject.info.url, id: item.id}}" class="image bg-cover"
                             :style="`background-image: url(${getItemImage(item)});`"></router-link>
                <div class="item-content">
                  <router-link :to="{name: 'Item', params:{ category: categoryObject.info.url, id: item.id}}">
                    <h3 class="product-name">{{$dynamic(item, item.title, 'title')}}</h3>

                  </router-link>

                </div>
                <div class="options">
                  <div class="price-box">
                    <div class="price-element price-old" v-if="item.price_old"> {{$filters.money(item.price_old)}} {{$store.getters.getSettings.symbol}}</div>
                    <div class="price-element"> {{$filters.money(item.price)}} {{$store.getters.getSettings.symbol}}</div>
                  </div>
                  <div class="right-options">
                    <router-link :to="{name: 'Item', params:{ category: categoryObject.info.url, id: item.id}}" class="default-btn btn-small">
                      <span class="btn-text">{{$static('catalog.info', 'Детальніше')}}</span>
                      <span class="icon icon-bag-outline"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <div ref="loadMore"></div>
          </ul>
        </div>
      </div>
    </div>
<!--    <div class="custom-header category-header bg-cover" v-if="categoryImage" :style="'background-image: url('+categoryImage+');'">-->
<!--            <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
<!--    </div>-->
<!--    <div class="category-header custom-header min-header" v-else>-->
<!--            <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
<!--    </div>-->

  </div>
  <div class="quote-box ceo-text">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="quote-holder">
            <h2 class="smallest">На даний момент, ми презентуємо  9 фітоформул серії CRYOX:</h2>
            <ol>
              <li>Detox Formula - Активація обміну речовин</li>
              <li>Liver Formula - Очищення печінки</li>
              <li>Stomach Formula - Підтримка роботи шлунку та травної системи</li>
              <li>Pancreas Formula - нормалізація роботи підшлункової залози</li>
              <li>Vascular Formula - Очищення судин</li>
              <li>Sedative Formula - Нормалізація тиску та заспокоєння</li>
              <li>Heart Formula - Нормалізації артеріального тиску та поліпшення роботи серця</li>
              <li>Kidney Formula - Підтримка функціонування нирок та сечовивідної системи</li>
              <li>Energy Formula - Підвищення енергії організму під час фізичних та психоемоційних навантажень.</li>
            </ol>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name    : 'Catalog',
  props   : ['category'],
  data() {
    return {
      optionSelect: [],
      items       : [],
      load        : false,
      page        : 1,
      perPage     : 12,
      total       : 0,
      more        : true,
      checkLoader : undefined,
      loadBefore  : 0
    }
  },
  beforeUnmount() {
    window.document.removeEventListener('scroll', this.onscroll);
  },
  updated() {
    if (this.more) {
      this.loadBefore = this.checkLoader.offsetTop;
    } else {
      let body        = document.body, html = document.documentElement;
      this.loadBefore = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 1000;
    }
  },
  mounted() {
    this.checkLoader = this.$refs.loadMore;
    window.document.addEventListener('scroll', this.onscroll);
    this.getItems();
    this.setSeo(this.categoryObject);
  },
  methods : {
    onscroll(e) {
      if (document.documentElement.scrollTop + window.innerHeight > this.loadBefore && this.more) {
        this.page += 1;
        this.getItems();
      }
    },
    getImage(item) {
      return `background-image: url(${item.image});`
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    isHot(item) {
      return item.advanced && item.advanced.hot
    },
    getItems(clear) {
      if (this.load) {
        return
      }
      this.load = true;
      if (clear) {
        this.page = 1;
        this.more = true;
      }
      let filter = {page: this.page, perPage: this.perPage};
      if (this.categoryName) {
        filter.category = this.categoryObject.id
      } else {
        filter.url = this.category
      }
      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).map(x => x ? x.id : x);
      }
      axios.post('/public/item/filter', {filter: filter, sortBy: "sort"}).then(response => response.data).then(data => {
        this.load = false;
        if (clear) {
          this.items = data.result;
        } else {
          this.items.push(...data.result);
        }
        this.page  = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
      }).catch(e => {
        this.load = false;
      })
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes("https://")){
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    getDiscount(item) {
      return item.preview ? (1 - Math.floor(Math.max(item.price / item.price_old, 0)*100)/100) * 100 | 0 : ''
    },
    setSeo(val) {
      if (val && val.info) {
        let title = val.info.title ? val.info.title : this.$dynamic(this.categoryObject, this.categoryName, 'name');
        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);
        let description = val.info.description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');
        let image = val.image ? `${window.location.protocol}//${window.location.host}${val.image}` : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  },
  computed: {
    tags() {
      return this.$store.getters.getTags.filter(x => x.info ? !x.info.hidden : true)
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    categoryObject() {
      return this.$store.getters.getCategories.find(i => i.info.url === this.category)
    },
    categoryName() {
      return this.categoryObject ? this.categoryObject.name : ''
    },
    categoryImage() {
      return this.categoryObject && this.categoryObject.image ? this.categoryObject.image : ''
    }
  },
  watch   : {
    category() {
      this.getItems(true)
    },
    optionSelect() {
      this.getItems(true)
    },
    categoryObject(val) {
      this.setSeo(val)
    }
  }
}
</script>

<style scoped>
</style>