<template>
  <div class="main-wrapper monochrome">
    <div class="default-wrapper ">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="personal_area">
              <div class="personal_area-sidebar" >
                <!--user panel if there is a login-->
                <div class="personal_area-title">
                  <h3>Особистий кабінет</h3>
                </div>
                <a class="close-sidebar-btn" href="javascript:void(0);">
                  <span class="icon icon-close"></span>
                </a>
                <div class="logged-in" v-if="userInfo">
                  <div class="logged-in-body">
                    <span class="user-avatar" v-if="userInfo.first_name">{{userInfo.first_name.substring(0, 1)}}</span>
                    <span class="user-avatar" v-else-if="userInfo.username">{{userInfo.username.substring(0, 1)}}</span>
                    <a href="#">
                      <span class="name">{{ userInfo.first_name }} {{ userInfo.last_name }}</span>
                      <span class="mail" v-if="userInfo.username">@{{ userInfo.username }}</span>
                    </a>
                  </div>
                </div>
                <!--end user panel if there is a login-->
                <div class="personal_area-menu">
                  <ul>
                    <li>
                      <router-link :to="{name: 'CabinetInfo'}" :exact-active-class="'active'">
                        <span class="icons-holder">
                          <span class="icon icon-user"></span>
                        </span>
                        <span class="text">Особисті дані</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{name: 'CabinetHistory'}" :active-class="'active'">
                        <span class="icons-holder">
                          <span class="icon icon-history"></span>
                        </span>
                        <span class="text">Історія замовлень</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{name: 'CabinetDelivery'}" :active-class="'active'">
                        <span class="icons-holder">
                          <span class="icon icon-location"></span>
                        </span>
                        <span class="text">Налаштування доставки</span>
                      </router-link>
                    </li>
                  </ul>

                </div>
                <div class="logout">
                  <a href="javascript:void(0)" @click="logout">Вийти з аккаунту</a>
                </div>
              </div>
              <!--personal area right side-->
              <router-view></router-view>
              <!--end personal area right side-->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="modal custom-modal fade" id="edit_logs" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span class="icon icon-close"></span>
          </button>
        </div>
        <div class="modal-body text-left">
          <h2 class="smallest text-center">Змінити логін / пароль</h2>
          <form>
            <div class="form-group">
              <label class="label-custom">Логін (электронна пошта)</label>
              <input type="text" class="form-control custom-input" value="anna@gmail.com">
            </div>
            <div class="form-group">
              <label class="label-custom">Новий пароль</label>
              <input type="text" class="form-control custom-input">
            </div>
            <div class="button-holder">
              <a href="#" class="default-btn btn-no-icon">
                <span class="btn-text">Зберегти зміни</span>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name   : 'Cabinet',
  data(){
    return{
      userInfo: undefined
    }
  },
  computed: {
    isAuth(){
      return this.$store.getters.isAuth
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('user_info')
      this.$router.go()
    }
  },
  mounted() {
    if (!this.isAuth) {
      this.$router.push({name: 'login'})
    }

    (function ($) {
      'use strict';
      // $('nav.sign_in .mobile-open-panel-trigger').on('click', function () {
      //
      //   $('.personal_area-sidebar').toggleClass('open');
      //   $('body').addClass('no-scroll');
      //   $('.frame-modal-wrapper-bg').addClass('is-visible');
      //   return false;
      // });
      $('.personal_area-sidebar .close-sidebar-btn').on('click', function () {

        $('.personal_area-sidebar').removeClass('open');
        $('body').removeClass('no-scroll');
        $('.frame-modal-wrapper-bg').removeClass('is-visible');
        return false;
      });
      $('.frame-modal-wrapper-bg').on('click', function () {

        $('.personal_area-sidebar').removeClass('open');
        $('body').removeClass('no-scroll');
        $(this).removeClass('is-visible');
        return false;
      });

      $('.show-password-trigger').on('mouseenter', function () {
        $('.password-input').attr('type', 'text');
      });
      $('.show-password-trigger').on('mouseleave', function () {
        $('.password-input').attr('type', 'password');
      });

    })(window.$);
  }
}
</script>

<style scoped>

</style>