import CabinetDelivery from '@/components/CabinetDelivery';
import CabinetHistory from '@/components/CabinetHistory';
import CabinetInfo from '@/components/CabinetInfo';
import Cabinet from '@/views/Cabinet';
import Certs from '@/views/Certs';
import Contact from '@/views/Contact';
import FAQ from '@/views/FAQ';
import FullCatalog from '@/views/FullCatalog';
import Login from '@/views/Login';
import { createRouter, createWebHistory } from 'vue-router'
import About from '../views/About';
import Catalog from '../views/Catalog';
import Delivery from '../views/Delivery';
import Dostupnist from '../views/Dostupnist';
import Home from '../views/Home';
import NotFound from '../views/NotFound';
// import Home from '../views/Home.vue'
import Offerta from '../views/Offerta';
import Order from '../views/Order';
import Policy from '../views/Policy';
import Item from '../views/Item';

const routes = [
  {
    path     : '/',
    name     : 'home',
    component: Home
  },
  {
    path     : '/contact',
    name     : 'contact',
    component: Contact
  },
  {
    path     : '/faq',
    name     : 'faq',
    component: FAQ
  },
  
  {
    path     : '/about',
    name     : 'about',
    component: About
  },
  {
    path     : '/policy',
    name     : 'policy',
    component: Policy
  },
  {
    path     : '/certs',
    name     : 'certs',
    component: Certs
  },
  {
    path     : '/offerta',
    name     : 'offerta',
    component: Offerta
  },
  {
    path     : '/dostup',
    name     : 'dostup',
    props    : true,
    component: Dostupnist
  },
  {
    path     : '/delivery',
    name     : 'delivery',
    component: Delivery
  },
  {
    path     : '/catalog',
    name     : 'full-catalog',
    component: FullCatalog
  },
  {
    path     : '/catalog/:category',
    name     : 'catalog',
    props    : true,
    component: Catalog
  },
  {
    path     : '/catalog/:category/:id',
    name     : 'Item',
    props    : true,
    component: Item
  },
  {
    path     : '/order',
    name     : 'Order',
    props    : true,
    component: Order
  },
  {
    path     : '/cabinet',
    name     : 'Cabinet',
    component: Cabinet,
    redirect: 'CabinetInfo',
    children : [
      {
        path     : '',
        name     : 'CabinetInfo',
        component: CabinetInfo
      },
      {
        path     : 'delivery',
        name     : 'CabinetDelivery',
        component: CabinetDelivery
      },
      {
        path     : 'history',
        name     : 'CabinetHistory',
        component: CabinetHistory
      },
      
    ]
  },
  {
    path     : '/login',
    name     : 'login',
    component: Login
  },
  {
    path     : '/:pathMatch(.*)*',
    name     : 'NotFound',
    component: NotFound
  }
];

const router = createRouter({
                              history       : createWebHistory(process.env.BASE_URL),
                              routes,
                              scrollBehavior: (to, from, savedPosition) => {
                                if (savedPosition) {
                                  return savedPosition;
                                } else if (to.hash) {
                                  return {
                                    selector: to.hash
                                  };
                                } else {
                                  return {x: 0, y: 0};
                                }
                              }
                            });

// let last_path = localStorage.getItem('last_path');

function initRecord() {
  router.beforeEach((to) => {
    if (window.change) return false;
    // console.log(to);
    (function ($) {
      'use strict';
      $('.frame-modal-wrapper-bg').removeClass('is-visible');
      $('.overlay-menu').removeClass('active');
      $('body').removeClass('no-scroll');
      
      $('#quick_cart').removeClass('opened');
      $('.open-nav').removeClass('is-visible');
      
    })(window.$);
    window.scrollTo(0, 0);
    document.querySelector('meta[property="og:url"]').setAttribute(
      'content',
      `${window.location.protocol}//${window.location.host}${to.path}`
    );
    localStorage.setItem('last_path', to.path);
    return true
    // return false
  });
  
  // let lastTs = 0;
  // window.addEventListener('scroll', (event) => {
  //   if (lastTs + 100 < event.timeStamp) {
  //
  //     lastTs = event.timeStamp;
  //     localStorage.setItem('save_y', window.pageYOffset);
  //   }
  // });
}

router.isReady().then(() => {
  // if (last_path && window.location.pathname !== last_path) {
  //   router.push(last_path);
  // }
  // try {
  // let lastSaveY = parseInt(localStorage.getItem('save_y'), 10);
  // setTimeout(() => {window.scrollTo(0, lastSaveY)}, 100)
  // } catch (e) {
  //   console.log(e)
  // }
  initRecord()
  
});

export default router
