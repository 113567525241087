<template>
  <div class="swiper-wrapper">
    <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
      <div class="carousel-item-body">
        <div class="text">
          <a :href="slide.link" class="default-btn btn-small light-btn">
            <span class="btn-text">Детальніше</span>
            <span class="icon icon-caret-right"></span>
          </a>
        </div>
        <div class="image bg-cover " :style="{'background-image': getImage(slide)}">
          <a :href="slide.link" ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'SwiperSlide',
  props: ['slides'],
  data() {
    return {plugin: undefined}
  },
  methods: {
    getImage(slide) {
      if (slide.url) {
        return decodeURI(`url(${slide.url})`)
      }
    }
  },
  mounted() {
    if (!this.plugin) {
      this.plugin = new window.Swiper('.special-gallery', {
        //effect: 'fade',
        centeredSlides: true,
        loop          : true,
        spaceBetween  : 15,
        slidesPerView : 'auto',
        speed         : 700,
        //autoplay: {
        //	delay: 3000,
        //	disableOnInteraction: false,
        //},

        pagination : {
          el            : '.swiper-pagination',
          dynamicBullets: false
        },
        breakpoints: {
          320 : {
            centeredSlides: false,
            spaceBetween  : 15
          },
          575 : {
            centeredSlides: false,
            spaceBetween  : 15
          },
          768 : {

            centeredSlides: true,
            spaceBetween  : 15
          },
          1024: {
            centeredSlides: true,
            spaceBetween  : 30
          },
          1200: {
            centeredSlides: true,
            spaceBetween  : 30
          }
        }
      });
    }
  },
  unmounted() {
    this.plugin.destroy()
    this.plugin = undefined
  }
}
</script>
