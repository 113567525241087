<template>
  <div class="main-wrapper">
    <div class="breadcrumb-holder">
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="breadcrumb">
              <li>
                <router-link :to="{name:'home'}">
                  {{$static('menu.home', 'Головна')}}
                </router-link>
              </li>
              <li>{{$static('404.text', 'Сторінку не знайдено')}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="default-wrapper page-not-found">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="not-found-holder text-center">
              <div class="num">404</div>
              <p class="caption h3">Вибачте, по Вашому запиту <br/>нічого не знайдено</p>
              <div class="button-holder">
                <router-link :to="{name: 'home'}" class="default-btn btn-dark btn-no-icon" >
                  <span class="btn-text">{{$static('404.back', 'Назад на головну')}}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'Not Found',
    data() {
    }
  }
</script>
