<template>
  <div class="main-wrapper monochrome">
    <div class="default-wrapper ">
      <div class="container">
        <div class="row" v-if="BOT_NAME">
          <div class="login-large-holder">
            <telegram-login-temp mode="callback"
                                 requestAccess="write"
                                 :telegram-login="BOT_NAME"
                                 @loaded='telegramLoadedCallbackFunc'
                                 @callback="yourCallbackFunction"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { telegramLoginTemp } from 'vue3-telegram-login'

export default {
  name: 'Login',
  components: {telegramLoginTemp},
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {
    if (this.isAuth){
      this.openCabinet()
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    isAuth(){
      return this.$store.getters.isAuth
    },
    BOT_NAME() {
      return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
    }
  },
  methods : {
    telegramLoadedCallbackFunc() {
      // console.log('script is loaded')
      this.isLoaded = true
    },
    yourCallbackFunction(user) {
      localStorage.setItem('user_info', JSON.stringify(user));
      this.$store.commit('setAuth', true)
      this.openCabinet()
    },
    openCabinet(){
      this.$router.push({name: "CabinetInfo"})
    }

  }
}
</script>

<style scoped>

</style>