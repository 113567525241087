<template>
  <div class="personal-area-body">
    <div class="title-heading">
      <h1 class="h2 smallest">Особисті дані</h1>
    </div>
    <div class="personal-settings">
      <form action="" class="default-form">
        <div class="personal-setting-holder">
          <div class="card custom-card no-border">
            <div class="form-inline">
              <div class="form-group">
                <label class="label-custom">Ім‘я <span>*</span></label>
                <input type="text" class="form-control custom-input" placeholder="Введіть ваше ім’я" v-model="name">
              </div>
              <div class="form-group">
                <label class="label-custom">Прізвище <span>*</span></label>
                <input type="text" class="form-control custom-input" placeholder="Введіть ваше прізвище" v-model="surname">
              </div>
              <div class="form-group">
                <label class="label-custom">Телефон <span>*</span></label>
                <input type="text" class="form-control custom-input" placeholder="+380" v-model="phone">
              </div>
              <div class="form-group">
                <label class="label-custom">Email</label>
                <input type="text" class="form-control custom-input" placeholder="somemail@gmail.com" v-model="email">
              </div>
              <!--            <div class="form-group">-->
              <!--              <label class="label-custom">Мова інтерфейсу <span>*</span></label>-->
              <!--              <input type="text" class="form-control custom-input" value="Українська">-->
              <!--            </div>-->
            </div>
          </div>
        </div>
        <div class="button-holder">
          <button class="default-btn btn-no-icon" @click.stop.prevent="save">зберегти зміни</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CabinetInfo',
  data() {
    return {
      account: undefined
    }
  },
  mounted() {
    this.getAccount()
    window.$('.personal_area-sidebar').removeClass('open');
    window.$('body').removeClass('no-scroll');
    window.$('.frame-modal-wrapper-bg').removeClass('is-visible');  },
  computed: {
    name   : {
      get() {
        return this.account?.info?.name ? this.account.info.name : ''
      },
      set(value) {
        this.account.info.name = value
      }
    },
    surname: {
      get() {
        return this.account?.info?.surname ? this.account.info.surname : ''
      },
      set(value) {
        this.account.info.surname = value
      }
    },
    email  : {
      get() {
        return this.account?.info?.email ? this.account.info.email : ''
      },
      set(value) {
        this.account.info.email = value
      }
    },
    phone  : {
      get() {
        return this.account?.info?.phone ? this.account.info.phone : ''
      },
      set(value) {
        this.account.info.phone = value
      }
    }
  },
  methods : {
    save() {
      if (!this.account) {
        return
      }
      let ok        = false
      let userInfo  = localStorage.getItem('user_info')
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true
      }
      let body = this.account
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        ok        = true
      }
      if (!ok) {
        return
      }
      return fetch(
          '/public/account/save',
          {
            method : 'POST',
            body   : JSON.stringify(body),
            headers: headers
          }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.account = json.result
        }
        if (json?.error){
          window.localStorage.removeItem('user_info')
          this.$router.push({'name':"login"})
        }
      })
    },
    getAccount() {
      let ok        = false
      let userInfo  = localStorage.getItem('user_info')
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true
      }
      let body = {}
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        ok        = true
      }
      if (!ok) {
        return
      }
      return fetch(
          '/public/account',
          {
            method : 'POST',
            body   : JSON.stringify(body),
            headers: headers
          }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.account = json.result
        }
        if (json?.error){
          window.localStorage.removeItem('user_info')
          this.$router.push({'name':"login"})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>