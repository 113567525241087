<template>
  <div class="category-panel" id="category_panel">
    <div class="category-panel-holder">
      <div class="panel-name">{{$static('categories.title', 'категорії')}}:</div>
      <ul class="categories-list" @mousedown="e => mouseDownHandlerTarget(e,'categories-list')">
        <li>
          <router-link :to="{name:'home'}" :activeClass="'active'">
            <span class="text">{{$static('menu.home', 'Головна')}}</span>
          </router-link>
        </li>

        <li v-for="(category,index) in categories" :key="'category-'+index">
          <router-link :to="'/catalog/'+category.info.url" :activeClass="'active'">
            <span class="text">{{$dynamic(category, category.name, 'name')}}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Categories',
    computed: {
      categories() {
        return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a,b) => a.order - b.order)
      }
    },
    methods   : {
      fancyClick(e) {
        if (window.change) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      mouseDownHandlerTarget(e, targetClass) {
        let target = e.target;
        while (!target.className.includes(targetClass)) {
          target = target.parentElement
        }
        window.ele = target;
        window.mouseDownHandler(e)
      },
    }

  }
</script>

<style scoped>

</style>