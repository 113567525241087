<template>
  <div class="main-wrapper">
    <div class="header-wrapper">
      <div class="home-header custom-header bg-cover" style="background-image: url(/images/home/header-image-full.webp);">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="header-content">
                <div class="empty"></div>
                <div class="text">
                  <h1><span>Підтримка природи</span> <br/>на кожен день</h1>
                  <div class="button-holder">
                    <router-link :to="{name: 'full-catalog'}" class="default-btn color-btn yellow-btn">
                      <span class="btn-text">Обрати комплекс</span>
                      <span class="icon icon-long-arrow-right"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="home-header custom-header" v-if="home && home.settings">-->
    <!--      <div class="header-content-alt bg-cover" v-if="home.settings.image" :style="headBackground">-->
    <!--        <router-link :to="{name: 'catalog', params:{ category: home.settings.url}}" style="display: block"></router-link>-->
    <!--      </div>-->
    <!--      <div class="header-content" v-else-if="home.settings.header || home.settings.subtitle">-->
    <!--        <h1 v-html="home.settings.header"></h1>-->
    <!--        <div class="subtitle" v-html="home.settings.subtitle"></div>-->
    <!--        <div class="button-holder" v-if="home.settings.button && home.settings.url">-->
    <!--          <router-link :to="{name: 'catalog', params:{ category: home.settings.url}}" class="default-btn ">-->
    <!--            <span class="btn-text" style="margin-right: 0">{{$static('home.to_catalog', 'До каталогу')}}</span>-->

    <!--          </router-link>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="quotes-holder">
      <div id="quotes-container">
        <ul class="quotes">
          <li>
            <span class="icon icon-inline-stomach"></span>
            <span class="text">Висока засвоюваність</span>
          </li>
          <li>
            <span class="icon icon-inline-leaves"></span>
            <span class="text">Натуральний склад</span>
          </li>
          <li>
            <img src="images/icons/inline-pipetka.svg" alt="">
            <span class="text">Без домішок</span>
          </li>
          <li>
            <span class="icon icon-inline-finger"></span>
            <span class="text">Індивідуальний підбір</span>
          </li>
          <li>
            <span class="icon icon-inline-stomach"></span>
            <span class="text">Висока засвоюваність</span>
          </li>
          <li>
            <span class="icon icon-inline-leaves"></span>
            <span class="text">Натуральний склад</span>
          </li>
          <li>
            <img src="/images/icons/inline-pipetka.svg" alt="">
            <span class="text">Без домішок</span>
          </li>
          <li>
            <span class="icon icon-inline-finger"></span>
            <span class="text">Індивідуальний підбір</span>
          </li>
          <li>
            <span class="icon icon-inline-stomach"></span>
            <span class="text">Висока засвоюваність</span>
          </li>
          <li>
            <span class="icon icon-inline-leaves"></span>
            <span class="text">Натуральний склад</span>
          </li>
          <li>
            <img src="/images/icons/inline-pipetka.svg" alt="">
            <span class="text">Без домішок</span>
          </li>
          <li>
            <span class="icon icon-inline-finger"></span>
            <span class="text">Індивідуальний підбір</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  <div class="special-offer">-->
    <!--    <div class="container">-->
    <!--      <div class="row">-->
    <!--        <div class="col">-->
    <!--          <div class="special-item-body">-->
    <!--            <div class="text">-->
    <!--              <a href="#global-detox-program" class="default-btn btn-small light-btn " data-toggle="modal">-->
    <!--                <span class="btn-text">Детальніше</span>-->
    <!--                <span class="icon icon-caret-right"></span>-->
    <!--              </a>-->
    <!--            </div>-->
    <!--            <img src="/images/specials/global_detox_program_single.webp" class=" hidden-device" alt="">-->
    <!--            <img src="/images/specials/global_detox_program_single_mob.webp" class=" visible-device" alt="">-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <BannerSlider/>
    <div class="catalog-wrapper section">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2>{{ $static('home.for_all', 'Товари для кожного') }}</h2>
            <div class="sorting-panel" v-if="tags && tags.length">

              <div class="sort-list">
                <div class="sorting-checkbox">
                  <input id="all" type="checkbox" v-model="all_tags" value="1">
                  <label for="all">
                    <span class="text">{{ $static('options.all', 'Усі') }}</span>
                  </label>
                </div>

                <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
                  <input :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                  <label :for="op.id">
                    <!--            <span class="pick" style="background-image: url('')" ></span>-->
                    <span class="pick" v-if="op.image" :style="getImage(op)"></span>
                    <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                    <span class="text">{{ $dynamic(op, op.name, 'name') }}</span>
                  </label>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="container catalog-container">
        <div class="row">
          <div class="col">
            <div class="products" v-if="items">
              <ul class="catalog-grid home-catalog" @mousedown="e => mouseDownHandlerTarget(e,'catalog-grid')">

                <li class="catalog-item" v-for="(item,index) in items" :key="index">
                  <div class="item-holder">
                    <div class="product-label label-new" v-if="isHot(item)">
                      <span class="label-text">New</span>
                    </div>
                    <div class="product-label label-sale" v-if="item.price_old">
                      <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
                    </div>
                    <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}" class="image bg"
                                 :style="`background-image: url(${getItemImage(item)});`">

                    </router-link>
                    <div class="item-content">
                      <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}">
                        <h3 class="product-name">{{ $dynamic(item, item.title, 'title') }}</h3>

                      </router-link>
                    </div>
                    <div class="options">
                      <div class="price-box">
                        <div class="price-element price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                          {{ $store.getters.getSettings.symbol }}
                        </div>
                        <div class="price-element"> {{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>

                      </div>
                      <div class="right-options">
                        <router-link :to="{name: 'Item', params:{ category:  categoryURL(item), id: item.id}}"
                                     class="default-btn btn-small">
                          <span class="btn-text">{{ $static('catalog.info', 'Детальніше') }}</span>
                          <span class="icon icon-bag-outline"></span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="catalog-item more-catalog-item" @click="getMore" v-if="more">
                  <div class="item-holder d-flex align-items-center text-center justify-content-center h-100">
                    <div class="more-content">
                      <div class="text-wrapper">
                        {{ $static('catalog.more_load', 'Завантажити ще') }}

                      </div>
                      <div class="icons-holder">
                        <img src="/images/icons/load.svg">
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="link-holder " v-if="home && home.settings && home.settings.url">
                <router-link :to="{name: 'full-catalog'}" class="default-btn btn-outline large-btn" style="margin: auto">
                  <span class="text">{{ $static('home.back_catalog', 'Перейти до каталогу') }}</span>
                  <span class="icon icon-caret-right"></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-html="getHTML"></div>

  </div>

</template>

<script>
import BannerSlider from '@/components/BannerSlider';
import axios from 'axios'
import { nextTick } from 'vue'

export default {
  name      : 'Home',
  components: {BannerSlider},
  data() {
    return {
      plugin      : undefined,
      items       : [],
      page        : 1,
      perPage     : 11,
      more        : true,
      swiper      : undefined,
      optionSelect: [],
      swiperReview: undefined,
      swiperMain  : undefined,
      home        : undefined
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    getHTML() {
      return this.landing?.template ? this.landing?.template : ''
    },
    lang() {
      return this.$lang()
    },
    logoSrc() {
      return this.settings && this.settings.logo ? this.settings.logo : '/images/logo.svg'
    },
    title() {
      return this.settings && this.settings.title ? this.settings.title : undefined
    },
    subtitle() {
      return this.settings && this.settings.subtitle ? this.settings.subtitle : undefined
    },
    tags() {
      return this.$store.getters.getTags
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    landing(){
      return this.$store.getters.landing
    }
  },
  methods : {
    getMore() {
      this.page += 1;
      this.getItems()
    },
    fancyClick(e) {
      if (window.change) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    mouseDownHandlerTarget(e, targetClass) {
      let target = e.target;
      while (!target.className.includes(targetClass)) {
        target = target.parentElement
      }
      window.ele = target;
      window.mouseDownHandler(e)
    },
    getImage(item) {
      return `background-image: url(${item.image});`
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    getDiscount(item) {
      return item.preview ? (1 - Math.floor(Math.max(item.price / item.price_old, 0)*100)/100) * 100 | 0 : ''
    },
    isHot(item) {
      return item.advanced && item.advanced.hot
    },
    getItems(clear) {
      if (this.load) {
        return
      }
      this.load  = true;
      let filter = {priority: true, perPage: this.perPage, page: this.page};
      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).map(x => x ? x.id : x);
      }
      axios.post('/public/item/filter', {filter: filter}).then(response => response.data).then(data => {
        this.load = false;
        if (clear) {
          this.items = data.result
        } else {
          this.items.push(...data.result);
        }
        this.page  = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
      }).catch(() => {
        this.load = false;
      })
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    categoryObjectID(item) {
      // return {id: 1}
      // console.log(this.$store.getters.getCategories, item.category);
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      // if (!result) {
      //
      // }
      return result
    },
    categoryURL(item) {
      // return {id: 1}
      // console.log(this.$store.getters.getCategories, item.category);
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    setup(val){
      setTimeout(() => {
        if (!this.plugin) {
          let options = {
            'autoPlay'          : false,
            'wrapAround'        : true,
            'cellAlign'         : 'center',
            'selectedAttraction': 0.01,
            'friction'          : 0.3,
            'pageDots'          : true
          }
          this.plugin = new window.Flickity('#flickity', options);
        }
      }, 300)

      if (val?.settings) {
        let title = val.settings.meta_title ? val.settings.meta_title : window.location.hostname;
        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = val.settings.meta_description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');

        let image = val.settings.image ? val.settings.image : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  },
  mounted() {
    this.getItems(true);
    if (this.landing){
      this.setup(this.landing)
    }
  },
  unmounted() {
    this.plugin.destroy()
    this.plugin = undefined
  },
  watch: {
    landing(val){
      this.setup(val)
    },
    optionSelect() {
      this.getItems(true)
    }
  }
}
</script>
