<template>
  <div class="main-wrapper monochrome">
    <div class="breadcrumb-holder">
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="breadcrumb">
              <li>
                <router-link :to="{name:'home'}">
                  {{$static('menu.home', 'Головна')}}
                </router-link>
              </li>
              <li>{{$static('menu.faq', 'Питання - Відповідь')}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="default-wrapper ">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="no-uppercase">Питання - <span>Відповідь</span></h1>
            <div class="accordion" id="faq">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <span class="num">#1</span>
                    <span class="text">Як обрати продукт для себе?</span>
                    <span class="icons"></span>
                  </button>
                </div>

                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#faq">
                  <div class="card-body">
                    <p>При виборі необхідно враховувати такі критерії:</p>

                    <ul class="custom-list">
                      <li>чого не вистачає організму та які в нього потреби </li>
                      <li>чи приймаєш зараз якість добавки</li>
                      <li>чи немає у тебе протипоказань</li>
                    </ul>
                    <p>Замовте консультацію спеціаліста.</p>

                    <p>Ми допоможемо з вибором ❤️</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <span class="num">#2</span>
                    <span class="text">Які є протипоказання?</span>
                    <span class="icons"></span>
                  </button>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#faq">
                  <div class="card-body">
                    <p>Індивідуальна чутливість до компонентів, діти віком до 14 років, вагітність, період лактації</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <span class="num">#3</span>
                    <span class="text">Як відбувається оплата і доставка по Україні?</span>
                    <span class="icons"></span>
                  </button>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#faq">
                  <div class="card-body">
                    <p>Оплатити можна онлайн на сайті додавши товар у кошик або при отриманні на пошті, або кур'єру. При оформленні замовлення через кошик – доступна оплата частинами.</p>
                    <p class="italic">Доставка здійснюється Новою Поштою та Укрпоштою </p>

                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    <span class="num">#4</span>
                    <span class="text">Як відбувається оплата і доставка в інші країни?</span>
                    <span class="icons"></span>
                  </button>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#faq">
                  <div class="card-body">
                    <p>Оплата здійснюється онлайн відразу за продукт та  доставку після оформлення рахунку менеджером. Ми відправляємо по всьому світу, крім росії, білорусі, ПМР та країн, у які заборонено ввезення БАДів, таких як ОАЕ, Саудівська Аравія, Ізраїль, Єгипет, Чехія, Туреччина. </p>
                    <p>Термін доставки — у середньому від 14 до 28 днів.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFive">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    <span class="num">#5</span>
                    <span class="text">Який термін придатності у продукції?</span>
                    <span class="icons"></span>
                  </button>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#faq">
                  <div class="card-body">
                    <p>Термін придатності нашої продукції – 36 місяців. На упаковці вказано дату виготовлення і закінчення терміну придатності. Процеси логістики і виробництва дозволяють тобі отримувати продукт, якому не більше 6 місяців з моменту капсулювання.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingSix">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    <span class="num">#6</span>
                    <span class="text">Чи потрібно здавати аналізи перед прийомом?</span>
                    <span class="icons"></span>
                  </button>
                </div>
                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#faq">
                  <div class="card-body">
                    <p>Це не обов’язково. Але, якщо ти підозрюєш, що у тебе дефіцит, і хочеш підібрати велику дозу для "лікування" дефіциту – тоді аналіз краще здати. Замовте консультацію спеціаліста.Ми допоможемо з вибором.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingSeven">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    <span class="num">#7</span>
                    <span class="text">Чи є повернення товару?</span>
                    <span class="icons"></span>
                  </button>
                </div>
                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#faq">
                  <div class="card-body">
                    <p>У дієтичних добавках не передбачено повернення продукції після відкриття упаковки, але ми так сильно впевнені в якості нашого продукту, що готові повернути тобі гроші, якщо щось не сподобається.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ'
}
</script>

<style scoped>

</style>