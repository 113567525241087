<template>
  <main>
    <div class="overlay-menu">
      <div class="overlay-top">
        <a class="close-menu-btn" href="javascript:void(0);">
          <span class="icon icon-close"></span>
        </a>
        <div class="logo">
          <img :src="logo">
        </div>
      </div>
      <div class="overlay-menu-wrapper">
        <div class="menu-list">
          <ul>
            <li class="visible-device">
              <router-link :to="{name:'home'}">
                <span class="text">{{ $static('menu.home', 'Головна') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'full-catalog'}">
                <span class="text">{{ $static('menu.catalog', 'Каталог') }}</span>
              </router-link>
              <a href="javascript:void(0);" class="category-menu-trigger d-none">
                <span class="text">{{ $static('menu.catalog', 'Каталог') }}</span>
                <span class="icon icon-caret-right"></span>
              </a>
            </li>
            <li>
              <router-link :to="{name:'about'}">
                <span class="text">{{ $static('menu.about', 'Про компанію') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'about', hash:'#sertificates'}">
                <span class="text">{{ $static('menu.sertificates', 'Сертифікати') }}</span>
              </router-link>
            </li>
            <li>
              <a href="#">
                <span class="text">{{ $static('menu.blog', 'Блог') }}</span>
              </a>
            </li>
            <li>
              <router-link :to="{name:'faq'}">
                <span class="text">{{ $static('menu.faq', 'Питання - Відповідь') }}</span>
              </router-link>

            </li>
          </ul>
          <ul>
            <li>
              <router-link :to="{name:'delivery'}">
                <span class="text">{{ $static('menu.delivery', 'Оплата та доставка') }}</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'contact'}">
                <span class="text">{{ $static('menu.contacts', 'Контакти') }}</span>
              </router-link>

            </li>
          </ul>
        </div>
      </div>
      <div class="menu-bottom">
        <div class="menu-bottom-left">
          <div class="small-text">Усі дзвінки у межах <br/>України безкоштовно</div>
          <div class="phone">
            <a href="tel:0800200010">0 800 20 00 10</a>
          </div>
          <div class="small-text">Служба підтримки <br/>працює з 9:00 до 21:00</div>
        </div>
        <div class="lang-panel">
          <div class="small-text">Мова:</div>

          <div class="dropdown show">
            <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">
              {{ current.title }}
            </a>
            <div class="dropdown-menu" x-placement="bottom-end">
              <a v-for="(l,index) in lang" :key="index" class="dropdown-item " href="javascript:void(0)"
                 @click="setLang(l)" :class="{'active': l.short === current.short}">
                {{ l.title }}
              </a>
            </div>
          </div>


        </div>
      </div>
      <!-- MENU SIDE ITEM-->
      <div class="menu-side category-side">
        <div class="menu-side-holder">
          <div class="menu-top-panel">
            <!-- close menu level trigger-->
            <a href="javascript:void(0);" class="back_sub_trigger">
              <span class="icon icon-caret-left"></span>
            </a>
            <!--end close menu level trigger-->
            <span class="name">Каталог</span>
          </div>

          <div class="content">

            <ul class="list">
              <li>
                <a href="#">
                  <span>Cryox</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Omega</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Collagen</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--END MENU SIDE ITEM-->
    </div>
    <div class="open-nav"></div>

    <Busked></Busked>

    <nav :class="{'sign_in': isAuth}"><!--ADD CLASS .sign_in to view user logined -->
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="nav-holder">
              <div class="left-nav">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="show-menu-btn">
                      <span class="icon icon-menu"></span>
                    </a>
                  </li>
                  <SearchItemComponent/>
                </ul>
              </div>
              <div class="logo">
                <router-link :to="{name: 'home'}">
                  <img :src="logo">
                </router-link>
              </div>
              <div class="right-nav">
                <ul>
                  <li class="call-trigger">
                    <a href="#consult_modal" data-toggle="modal">
                      <span class="icon icon-phone"></span>
                    </a>
                  </li>
                  <li class="quick-card-sticker" id="quick_card_trigger">
                    <a href="javascript:void(0);">
                      <span class="notification">{{ busked.length }}</span>
                      <span class="icon icon-bag"></span>
                    </a>
                  </li>
                  <li class="sign-trigger-holder" @click="$router.push({name: 'login'})">
                    <a href="javascript:void(0);" class="sign-trigger"></a>
                    <a href="javascript:void(0);" class="mobile-open-panel-trigger"></a>
<!--                    <div class="text-holder" v-if="username">-->
<!--                      <span class="text">{{ username }}</span>-->
<!--                    </div>-->
                    <a href="javascript:void(0);" class="link">
                      <span class="icon icon-user-fill"></span>
                      <span class="icon icon-user"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </nav>
    <MenuHeader/>
    <div class="free-stroke">
      <div class="text">Безкоштовна доставка від <span>1500 грн</span></div>
    </div>
    <Categories class="d-none"></Categories>

    <router-view/>
  </main>
  <footer>
    <div class="top-footer">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="subscribe">
              <div class="text">
                <h2 class="smallest">Підписуйся та отримуй спеціальні пропозиції, новинки бренду та корисні статті про здоров'я</h2>
              </div>
              <div class="subscribe-form">
                <form action="">
                  <div class="form-group">
                    <button class="default-btn btn-no-icon">Стежити</button>
                    <input type="text" class="form-control custom-input" placeholder="Ел.пошта">
                  </div>
                </form>
              </div>
            </div>
            <div class="socials" v-if="settings.instagram || settings.telegram || settings.facebook">
              <ul>
                <li>
                  <a v-if="settings.facebook" :href="settings.facebook">
                    <span class="soc-text">#facebook</span>
                    <span class="icon icon-facebook"></span>
                  </a>
                </li>
                <li>
                  <a v-if="settings.instagram" :href="settings.instagram">
                    <span class="soc-text">#instagram</span>
                    <span class="icon icon-inst"></span>
                  </a>
                </li>
                <li>
                  <a v-if="settings.telegram" :href="settings.telegram">
                    <span class="soc-text">#telegram</span>
                    <span class="icon icon-telegram"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer-bottom">
              <div class="logo-holder">
                <router-link :to="{name: 'home'}" class="logo">
                  <img :src="logo">
                </router-link>
                <div class="disclaymer">
                  <div class="text">
                    <p>Консультація та інформація несуть ознайомлювальний характер. <br/>FDA відіграє особливу роль в регулюванні
                      нутрицевтичної індустрії</p>
                    <p>Дієтична добавка не є лікарським засобом</p>
                  </div>
                </div>
              </div>
              <div class="menus">
                <ul>
                  <li>
                    <router-link :to="{name:'about'}">
                      <span class="text">{{ $static('menu.about', 'Про нас') }}</span>
                    </router-link>
                  </li>
                  <li>
                    <a href="#">
                      <span class="text">{{ $static('menu.sertificates', 'Сертифікати') }}</span>
                    </a>
                  </li>
                  <li>
                    <router-link :to="{name:'full-catalog'}">
                      <span class="text">{{ $static('menu.catalog', 'Каталог') }}</span>
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="#">
                      <span class="text">{{ $static('menu.blog', 'Блог') }}</span>
                    </a>
                  </li>
                  <li>
                    <router-link :to="{name:'faq'}">
                      <span class="text">{{ $static('menu.faq', 'Питання - Відповідь') }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'delivery'}">
                      <span class="text">{{ $static('menu.delivery', 'Оплата та доставка') }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'contact'}">
                      <span class="text">{{ $static('menu.contacts', 'Контакти') }}</span>
                    </router-link>
                  </li>

                </ul>
                <div class="logos">
                  <img src="/images/icons/mastercard.svg" alt="mastercard">
                  <img src="/images/icons/visa.svg" alt="visa">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="disclaymer visible-device">
      <div class="text">
        <p>Консультація та інформація несуть ознайомлювальний характер. <br/>FDA відіграє особливу роль в регулюванні нутрицевтичної
          індустрії</p>
        <p>Дієтична добавка не є лікарським засобом</p>
      </div>
    </div>
    <div class="copyright-footer">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="copy-holder">
              <div class="copyright">
                &copy; {{ $static('footer.copy', `2023 Копіювання матеріалів сайту без дозволу заборонено`) }}
              </div>
              <div class="bottom-links">
                <router-link :to="{name:'offerta'}">
                  Угода користувача
                </router-link>
                <router-link :to="{name:'policy'}">
                  <span class="text">{{ $static('menu.policy', 'Політика конфіденційності') }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="intrusive-popup"><!--ADD CLASS .is-opened to make the window appear-->
    <div class="popup-wrapper">
      <div class="popup-top ">
        <a href="javascript:void(0)" class="remove-intrusive-popup">
          <span class="icons icon-close"></span>
        </a>
        <div class="popup-top-content">
          <h2>Отримай <span>індивідуальну</span> консультацію</h2>
          <div class="caption">Ми підберем <span>НАЙКРАЩИЙ</span> курс, <br/>який підійде саме для тебе</div>
          <form>
            <div class="form-group">

              <input type="text" class="form-control custom-input" placeholder="+38">
            </div>
            <div class="button-holder">
              <a href="#" class="default-btn btn-no-icon">
                <span class="btn-text">Замовити консультацію</span>
              </a>
            </div>
          </form>
        </div>
        <div class="image bg-cover" style="background-image: url(/images/intrusive-popup/image.jpg);"></div>

      </div>
      <div class="popup-bottom">
        <div class="label-custom">Додаткова вигода:</div>
        <ul>
          <li>
            <div class="d-flex align-items-center">
              <div class="icons-holder">
                <img src="/images/intrusive-popup/delivery.svg" alt="">
              </div>
              <div class="text">
                <p>Безкоштовна доставка</p>
                <span>При замовленні курсу</span>
              </div>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center">
              <div class="icons-holder">
                <img src="/images/intrusive-popup/call-center.svg" alt="">
              </div>
              <div class="text">
                <p>Безкоштовна консультація</p>
                <span>Отримай консультацію зі і спеціалістом</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="popup-bg"></div>
  </div>


  <div class="modal custom-modal fade" id="consult_modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span class="icon icon-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <h2 class="smallest">{{ $static('modal-callback.title', 'Отримати консультацію') }}</h2>
          <p>{{
              $static('modal-callback.text', `Введіть свій номер телефону нижче,
            і наш менеджер зв’яжеться з Вами
            за декілька хвилин`)
            }}</p>
          <form>
            <div class="form-group" :class="{'error': errors['phone']}">
              <input type="text" class="form-control custom-input" @focus="delError('phone')" placeholder="+380998787858"
                     v-model="callbackPhone">
              <p class="help-text" v-for="(err,key) in errors['phone']" :key="key">{{ err }}</p>
            </div>
            <div class="button-holder">
              <a href="javascript:void(0)" class="default-btn btn-no-icon" @click="sendCallback">
                <span class="btn-text">{{ $static('modal-callback.button', 'Отримати консультацію') }}</span>
              </a>
            </div>
          </form>
          <div class="attention-apply">{{ $static('modal-callback.info1', 'При кліці на кнопку я погоджуюся з') }}
            <router-link :to="{name: 'policy'}" target="_blank">
              {{$static('modal-callback.info2', `політикою обробки персональних даних`)}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal custom-modal large-modal fade" id="global-detox-program" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span class="icon icon-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="product-header">

            <div class="product-header-holder">
              <div class="product-header-info">
                <h1>Комплекс <span>Global Detox Program</span></h1>
                <div class="custom-tabs">
                  <ul class="nav nav-tabs" id="modal-product-tabs-1" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" href="#description-program" role="tab" aria-selected="true">Опис</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#benefit-program" role="tab" aria-selected="false">користь</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#ingredients-program" role="tab" aria-selected="false">інгредієнти</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#method-program" role="tab" aria-selected="false">спосіб застосування</a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContentModal">
                    <div class="tab-pane fade show active" id="description-program" role="tabpanel">
                      <div class="text">
                        <p>Кожен елемент CRYOX DETOX PROGRAM підібраний для поліпшення загального ефекту. </p>
                        <p class="content-bold">До програми входить 3 формули:</p>
                        <ol>
                          <li><span class="content-bold">Detox Formula -</span> Активація обміну речовин</li>
                          <li><span class="content-bold">Liver Formula -</span> Нормалізація роботи печінки</li>
                          <li><span class="content-bold">Vascular Formula -</span> Очищення судин</li>
                        </ol>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="benefit-program" role="tabpanel">
                      <h6>#1 DETOX FORMULA</h6>
                      <ul class="custom-list">
                        <li>Нормалізує рівень обміну речовин в організмі (насіння каштана кінського, квітки глоду, стовпчики із примочками
                          кукурудзи, плоди кропу)
                        </li>
                        <li>Підтримує стабільну і здорову роботу травлення (плоди розторопші, трава хвоща, трава череди, листя сени)</li>
                        <li>Допомагає виводити продукти обміну речовин з організму (трава собачої кропиви, коріння кульбаби, листя м'яти
                          перцевої)
                        </li>
                      </ul>
                      <h6>#2 LIVER FORMULA</h6>
                      <ul class="custom-list mb-0">
                        <li>Очищення, відновлення, захист функцій печінки (плоди розторопші, стовпчики з примочками кукурудзи, безсмертник,
                          ромашка, коріння кульбаби)
                        </li>
                      </ul>

                      <div class="collapse" id="benefit-more">
                        <ul class="custom-list">
                          <li>Нормалізація процесів жовчовиділення (Коріння кульбаби, листя м'яти перцевої, ромашка)</li>
                          <li>Поліпшення процесів очищення і травлення (трава собачої кропиви, коріння кульбаби, листя м'яти перцевої)</li>
                        </ul>
                        <h6>#3 VASCULAR FORMULA</h6>
                        <ul class="custom-list">
                          <li>Зміцнює судини та покращує процес обміну речовин шипшина, брусниця, квітки глоду, насіння каштана кінського,
                            софора
                          </li>
                          <li>Очищає судини, покращує кровообіг і тиск (глід, хвощ, каштан, софора)</li>
                          <li>Відновлює нормальний рівень холестерину (береза, листя м'яти перцевої, софора)</li>
                        </ul>
                      </div>
                      <div class="link-more">
                        <a data-toggle="collapse" href="#benefit-more" role="button" aria-expanded="false"
                           aria-controls="product-desc-more">
                          <span class="text">Читати повністю</span>
                        </a>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="ingredients-program" role="tabpanel">
                      <h6>#1 DETOX FORMULA - 11 цілющих трав</h6>
                      <ul class="custom-list">
                        <li>Квіти глоду</li>
                        <li>Плоди розторопші</li>
                        <li>Корінь кульбаби</li>
                        <li>Листя м'яти</li>
                        <li>Листя сенни</li>
                        <li>Плоди кропу</li>
                        <li>Плоди каштану</li>
                        <li>Трава причепи</li>
                        <li>Трава пустирника</li>
                        <li>Кукурудзяні приймочки</li>
                        <li>Трава хвощу</li>
                      </ul>
                      <h6>#2 LIVER FORMULA - 7 цілющих трав</h6>
                      <ul class="custom-list mb-0">
                        <li>Плоди розторопші плямистої</li>
                        <li>Квітки цмину піскового</li>
                        <li>Трава причепи</li>
                        <li>Корені кульбаби лікарської</li>
                      </ul>
                      <div class="collapse" id="ingredients-more">
                        <ul class="custom-list">
                          <li>Стовпчики з приймочками кукурудзи</li>
                          <li>Квітки ромашки лікарської</li>
                          <li>Листя м'яти перцевої</li>
                        </ul>
                        <p class="content-bold italic">Допоміжні речовини:</p>
                        <ul class="custom-list">
                          <li>Карбоксиметилцелюлоза</li>
                          <li>Крохмаль</li>
                          <li>Кальцію стеарат</li>
                        </ul>
                        <h6>#3 VASCULAR FORMULA - 11 цілющих трав</h6>
                        <ul class="custom-list">
                          <li>Квіти софори японської</li>
                          <li>Хвоя сосни звичайної</li>
                          <li>Насіння каштану кінського</li>
                          <li>Листя брусниці</li>
                          <li>Листя берези</li>
                          <li>Плоди шипшини собачої</li>
                          <li>Квіти глоду криваво-червоного</li>
                          <li>Кореневища з коренями левзеї</li>
                          <li>Листя м'яти перцевої</li>
                          <li>Трава хвощу польового</li>
                          <li>Стовпчики з приймочками кукурудзи</li>
                        </ul>
                        <p class="content-bold italic">Допоміжні речовини:</p>
                        <ul class="custom-list">
                          <li>Карбоксиметилцелюлоза</li>
                          <li>Крохмаль</li>
                          <li>Кальцію стеарат</li>
                        </ul>
                      </div>
                      <div class="link-more">
                        <a data-toggle="collapse" href="#ingredients-more" role="button" aria-expanded="false"
                           aria-controls="product-desc-more">
                          <span class="text">Читати повністю</span>
                        </a>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="method-program" role="tabpanel">
                      <p><span class="content-bold">Рекомендовано вживати:</span> дорослим та дітям від 14 років по 1-2 таблетки 2 рази на
                        день за 20 хвилин до вживання їжі, запити водою. Термін вживання 2 - 6 тижні. За необхідності курс можна повторити з
                        15-денною перервою. Не перевищувати рекомендовану добову дозу.</p>
                      <p>Перед застосуванням потребує консультації з лікарем.</p>
                      <p><span class="content-bold">Протипоказання:</span> індивідуальна чутливість до компонентів, діти до 14 років,
                        вагітні, жінки, що годують немовля.</p>
                    </div>
                  </div>
                </div>
                <div class="quick-info">
                  <div class="left-content">
                    <div class="image">
                      <img src="/images/icons/tablet.svg" alt="">
                    </div>
                    <div class="text">
                      <small>У комплексі:</small>
                      <p>3 упаковки </p>
                      <div class="label"><span>3 x 45 днів прийому</span></div>
                    </div>
                  </div>
                  <div class="price-box">
                    <div class="price-element price-old">5790 грн</div>
                    <div class="price-element">4050 грн</div>
                  </div>
                </div>
                <div class="options d-flex">
                  <div class="nt-spinner">
                    <button class=" nt-btn btn_less icons icon-minus" type="button"></button>
                    <input type="text" class="form-control " value="1">
                    <button class=" nt-btn btn_more icons icon-plus" type="button"></button>
                  </div>
                  <div class="button-holder">
                    <a href="#" class="default-btn">
                      <span class="btn-text">До кошика</span>
                      <span class="icon icon-bag-outline"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="product-header-imgs">
                <div class="large-label product-label label-sale">
                  <span class="label-text">-30%</span>
                </div>
                <div class="single-image">
                  <div class="image bg" style="background-image: url(/images/products/global-detox-program.png);"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="thanks-modal" id="thx" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title', 'Дякуємо за покупку!') }}</div>
        <p>{{ $static('thanks.subtitle', 'Переходимо до оплати...') }}</p>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="thx-callback" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title2', 'Дякуємо за заявку!') }}</div>
        <p>{{ $static('thanks.subtitle2', 'Наш менеджер скоро вам зателефонує') }}</p>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="errorModal" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.error', 'Сталася помилка') }}</div>
        <p>{{ $static('thanks.subtitle_error', 'Будь ласка перевірте ваші данні і спробуйте ще.') }}</p>
      </div>
    </div>
  </div>

  <div class="frame-modal-wrapper-bg"></div>
</template>

<script>
import SearchItemComponent from '@/SearchItemComponent';
import axios from 'axios'
import Busked from './components/Busked';
import Categories from './components/Categories';
import MenuHeader from './components/MenuHeader';

export default {
  components: {SearchItemComponent, MenuHeader, Categories, Busked},
  data() {
    return {
      callbackPhone: '',
      errors       : {},
      isDemo       : false,
    }
  },
  methods: {
    delError(key) {
      delete this.errors[key]
    },
    setLang(lang) {
      // console.log('setLang', lang)
      this.current = lang;
      this.$store.commit('setLang', lang)
      this.fetchLang();
    },
    validate() {
      this.errors = {};
      if (this.callbackPhone.length < 8) {
        this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
        return false
      }
      return true;
    },
    sendCallback() {
      let valid = this.validate();
      if (!valid) {
        return
      }
      window.document.querySelector('#consult_modal .close').click();
      window.document.querySelector('#thx-callback').classList.add("is-visible");
      return fetch(
          '/public/callback',
          {
            method : 'POST',
            body   : JSON.stringify({phone: this.callbackPhone})
          }
      ).then(response => response.json()).then(() => {
        setInterval(() => {
          window.document.querySelector('#thx-callback').classList.remove("is-visible");
          if (window.Telegram) {
            window.Telegram.WebApp.close();
          }
        }, 2000);

        return []
      })
    },
    fetchDeliveryOptions() {
      return fetch('/public/delivery').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setDeliveryOptions', data)
            }
          })
    },

    fetchSettings() {
      return fetch('/public/settings').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setSettings', data);
              let check = ['code1', 'code2', 'code3'];
              check.forEach(x => {
                if (data[x]) {
                  // let elem           = document.createElement('div');
                  // elem.style.display = 'none';
                  // elem.innerHTML     = data[x];

                  let newHTML = document.querySelector('head').innerHTML;
                  newHTML += data[x];

                  document.querySelector('head').innerHTML = newHTML;
                  // document.querySelector('head').appendChild(elem)
                }
              });
            }

          })
    },
    fetchHomePage() {
      return fetch('/public/template/home').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setLanding', data);
            }
          })
    },

    fetchLang() {
      fetch(this.current.value).then(response => response.json()).then(data => {
        if (data) {
          localStorage.setItem('$lang', this.current.short);
          this.$load(data, this.current.short)
        }
      })
    },
    fetchOrigin() {
      return fetch('/storage/i18n/original.json').then(response => response.json()).then(data => {
        if (data) {
          this.$load(data, this.current.short)
        }
      })
    },
    fetchAccount() {
      let ok        = false
      let userInfo  = localStorage.getItem('user_info');
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true;
      }
      let body = {}
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        this.$store.commit('setUserInfo', body.user)
        ok = true
      }
      if (!ok) {
        return new Promise(resolve => resolve())
      }
      return fetch(
          '/public/account',
          {
            method : 'POST',
            body   : JSON.stringify(body),
            headers: headers
          }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.$store.commit('setAccount', json.result)
        }
        if (json?.error) {
          window.localStorage.removeItem('user_info')
        }
      })
    },
  },
  mounted() {
    const url = new URL(location.href);
    const params = new URLSearchParams(url);
    url.searchParams.forEach(function (val, key) {
      localStorage.setItem(key, val)
    });

    window.utm = {
      UTM_CAMPAIGN: "",
      UTM_CONTENT: "",
      UTM_MEDIUM: "",
      UTM_SOURCE: "",
      UTM_TERM: ""
    };
    Object.keys(window.utm).forEach(k => {
      let temp = localStorage.getItem(k.toLowerCase());
      if (temp){
        window.utm[k] = temp;
      }
    });

    window.goTo   = this.$router.push
    window.pos    = {top: 0, left: 0, x: 0, y: 0};
    window.ele    = undefined;
    window.change = false;
    this.isDemo   = location.hostname === 'demo.ux.im';

    let initData = localStorage.getItem('initData');
    let userInfo = localStorage.getItem('user_info');
    this.$store.commit('setAuth', initData || userInfo)

    function isTouchDevice() {
      return (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0));
    }

    window.istouch          = isTouchDevice();
    window.mouseDownHandler = function (e) {
      window.pos = {
        // The current scroll
        left: window.ele.scrollLeft,
        top : window.ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY
      };

      // Change the cursor and prevent user from selecting the text
      // ele.style.cursor = 'grabbing';

      if (!window.istouch) {
        window.ele.style.userSelect = 'none';
        window.change               = false;
        document.addEventListener('mousemove', window.mouseMoveHandler);
        document.addEventListener('mouseup', window.mouseUpHandler);
      }
    };

    window.mouseMoveHandler = function (e) {
      // console.log('mouseMoveHandler', e)
      // How far the mouse has been moved
      window.change         = true;
      const dx              = e.clientX - window.pos.x;
      const dy              = e.clientY - window.pos.y;
      // Scroll the element
      window.ele.scrollTop  = window.pos.top - dy;
      window.ele.scrollLeft = window.pos.left - dx;
    };

    window.clearChange    = function (e) {
      window.change = false;
    }
    window.mouseUpHandler = function () {
      // console.log('mouseUpHandler')
      document.removeEventListener('mousemove', window.mouseMoveHandler);
      document.removeEventListener('mouseup', window.mouseUpHandler);
      setTimeout(window.clearChange, 300)
    };

    document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

    if (window.Telegram) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
      // document.getElementById('subtitle').innerText = navigator.userAgent;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.document.addEventListener('touchmove', function (e) {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        }, {passive: false});
        window.Telegram.WebApp.onEvent('viewportChanged', window.Telegram.WebApp.expand)
      }
      if (window.Telegram.WebApp.initData && window.Telegram.WebApp.initData.length) {
        localStorage.setItem('initData', window.Telegram.WebApp.initData)
      }
    }

    fetch('/public/plugin').then(response => response.json()).then(data => {
      data.forEach(x => {
        console.log('commit', 'set'+x.type, x.list)
        this.$store.commit('set'+x.type, x.list)
      });
    });

    (function ($) {
      'use strict';
      // $('#search-trigger').on('click', function () {
      //   $('body').addClass('no-scroll');
      //   $('.search-modal-wrapper').addClass('opened');
      //   $('.frame-modal-wrapper-bg').addClass('is-visible');
      //   return false;
      // });
      $('.frame-modal-wrapper-bg').on('click', function () {
        $('.search-modal-wrapper').removeClass('opened');
        return false;
      });
      $('.show-password-trigger').on('mouseenter', function () {
        $('.password-input').attr('type', 'text');
      });
      $('.show-password-trigger').on('mouseleave', function () {
        $('.password-input').attr('type', 'password');
      });

      $('.sign-trigger').on('click', function () {
        $('.auth-modal').addClass('register').addClass('is-opened');
        $('html').addClass('open_auth');

      });
      $('nav.sign_in .mobile-open-panel-trigger').on('click', function () {

        $('.personal_area-sidebar').toggleClass('open');
        $('body').addClass('no-scroll');
        $('.frame-modal-wrapper-bg').addClass('is-visible');
        return false;
      });
      $('.auth-modal .remove-auth-modal').on('click', function () {
        $('html').removeClass('open_auth');
        $('.auth-modal').removeClass('log_in').removeClass('forgot').removeClass('register').removeClass('is-opened');

      });
      $('.auth-modal .modal-bg').on('click', function () {
        $('html').removeClass('open_auth');
        $('.auth-modal').removeClass('log_in').removeClass('forgot').removeClass('register').removeClass('is-opened');

      });
      $('#category_panel').stick_in_parent({
                                             offset_top: 0
                                           });

      $('.show-menu-btn').on('click', function () {
        $('.overlay-menu').addClass('active');
        $('body').addClass('no-scroll');
        $('.open-nav').addClass('is-visible');
        return false;
      });
      $('.overlay-menu .close-menu-btn').on('click', function () {
        $('.overlay-menu').removeClass('active');
        $('body').removeClass('no-scroll');
        $('.open-nav').removeClass('is-visible');
        return false;
      });
      $('.overlay-menu .category-menu-trigger').on('click', function () {
        $('.overlay-menu .menu-side').addClass('open');
        return false;
      });
      $('.overlay-menu .menu-side .back_sub_trigger').on('click', function () {
        $('.overlay-menu .menu-side').removeClass('open');
        return false;
      });
      $('.open-nav').on('click', function () {
        $('.overlay-menu').removeClass('active');
        $('body').removeClass('no-scroll');
        $('.overlay-menu .menu-side').removeClass('open');
        $(this).removeClass('is-visible');
        return false;
      });
      $(function () {
        if ($(window).scrollTop() >= 50) {

          $('header').addClass('sticked');
          $('nav').addClass('sticked');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 50) {

            $('header').addClass('sticked');
            $('nav').addClass('sticked');
          } else {

            $('header').removeClass('sticked');
            $('nav').removeClass('sticked');
          }
        });
      });

      $(function () {
        if ($(window).scrollTop() >= 500) {
          $('.fixed-quick-panel').addClass('sticked');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 500) {
            $('.fixed-quick-panel').addClass('sticked');
          } else {
            $('.fixed-quick-panel').removeClass('sticked');
          }
        });

      });

    })(window.$);

    //-- hotfix
    let assets = {
      'icon-menu-1': 'icon-pills',
      'icon-menu-2': 'icon-mortartboard'
    };
    //--

    let options = axios.get('/public/options').then(response => response.data).then(data => {
      data.result.forEach(o => {
        /*eslint no-case-declarations: "off"*/
        switch (o.option_id) {
        case 'tags':
          this.$store.commit('setTags', o.list);
          break;
        case 'category':
          this.$store.commit('setCategories', o.list);
          break;
        default:
          this.$store.commit('setOptions', o);
          break;
        }
      });

      if (data.settings) {
        this.$store.commit('setOptionSettings', data.settings);
      }
    });

    // options = axios.get('/public/options/categories').then(response => response.data).then(data => {
    //   data.result.forEach(o => {
    //
    //     /*eslint no-case-declarations: "off"*/
    //     if (o.option_id === 'category') { this.$store.commit('setCategories', o.list); }
    //   })
    // });

    // let land = fetch('/storage/landing.json').then(response => response.json()).then(data => {
    //   if (data) {
    //     this.$store.commit('setLanding', data);
    //   }
    // });

    let delivery = this.fetchDeliveryOptions();
    let settings = this.fetchSettings();
    let home     = this.fetchHomePage();
    let account     = this.fetchAccount();

    Promise.all([options, delivery, account]).then(() => {
      window.$('.preloader-page').removeClass('proccess-load')
    })
  },
  computed: {
    isAuth(){
      return this.$store.getters.isAuth
    },
    current() {
      return this.$store.getters.getLang
    },
    settings() {
      return this.$store.getters.getSettings
    },
    busked() {
      return this.$store.getters.getBusked
    },
    lang() {
      return [
        {
          icon : '/storage/i18n/ua.svg',
          title: 'Укр',
          short: 'UA',
          value: '/storage/i18n/original.json'
        },
        {
          icon : '/storage/i18n/ru.svg',
          title: 'Мова',
          short: 'SRU',
          value: '/storage/i18n/ru.json'
        },
        {
          icon : '/storage/i18n/en.svg',
          title: 'Eng',
          short: 'EN',
          value: '/storage/i18n/en.json'
        }
      ]

    },
    logo() {
      return this.settings && this.settings.logo ? this.settings.logo : ''
    },
    initData() {
      return window.location.href
    },
    s_info() {
      return this.settings && this.settings.s_info ? this.settings.s_info : false
    },
    phones() {
      return this.settings && this.settings.phone ?
          this.settings.phone.split(',').map(x => x.trim())
              .map(x => {return {real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+')}}) : false
    },

    local() {
      return this.$local()
    },
    dynamics() {
      return this.$dynamics()
    }
  }

}
</script>
