<template>
  <div class="personal-area-body">
    <div class="title-heading">
      <h1 class="h2 smallest ">Історія замовлень</h1>
    </div>
    <div class="personal-order-list-table" v-if="list.length">
      <div class="personal-order-list-top">
        <div class="name name-date_status">Дата/статус:</div>
        <div class="name name-price">Сума замовлення:</div>
        <div class="name name-product">Товар:</div>
      </div>
      <!--personal area order list item-->
      <div class="personal-order-list-item" v-for="(order, index) in list" :key="index">
        <div class="list-item-top" data-toggle="collapse" :data-target="'#item'+index" aria-expanded="false" :aria-controls="'item'+index">
          <div class="date_status" :class="getOrderStatus(order)">
            <div class="date_status-body">
              <span class="date"><span class="order-number">№{{ order.id }}</span> от 10.10.2020 </span>
              <span class="status">{{ order.status }}</span>
            </div>
            <div class="price-element">{{ order.total }} грн</div>
          </div>
          <div class="price-box">

            <div class="price-element">{{ order.total }} грн</div>
          </div>
          <div class="product-previews">
            <div class="product-preview bg" v-for="(item, index) in buskedPreview(order)"
                 :key="index" :style="`background-image: url(${getItemImage(item)});`"></div>
          </div>
          <div class="icons-holder">
            <span class="icon icon-caret-down"></span>
          </div>
        </div>
        <!-- full information about order-->
        <div class="collapse" :id="'item'+index">
          <div class="personal-order-list-item-body">
            <div class="personal-order-list-item-holder">
              <ul class="personal-data-list">
                <li>Доставка “{{ order.delivery.manifest.title }}”</li>
                <li>{{ getPayName(order) }}</li>
                <li>{{ order.info.name }} {{ order.info.surname }}</li>
                <li>{{ order.delivery.warehouse }}</li>
                <li>
                  <a href="javascript:void(0)">{{ order.info.phone }}</a>
                </li>
              </ul>

              <div class="personal-order-product-wrapper">
                <!-- order product item-->
                <div class="product-item" v-for="(item, index) in order.info.busked" :key="index">
                  <div class="item-name">
                    <span class="img-holder">
                      <router-link :to="{name: 'Item', params:{'id': item.id, 'category': categoryURL(item)}}">
                        <div class="image bg" :style="`background-image: url(${getItemImage(item)});`"></div>
                      </router-link>
                    </span>
                    <div class="text">
                      <div class="name">
                        <router-link :to="{name: 'Item', params:{'id': item.id, 'category': categoryURL(item)}}">
                          {{ item.title }}
                        </router-link>
                      </div>
                      <div class="subname" v-if="item?.advanced?.text7">{{ item.advanced.text7 }}</div>
                    </div>
                  </div>

                  <div class="option-box">
                    <div class="item">
                      <div class="item-name">Ціна:</div>
                      <div class="value">{{ item.price }} грн</div>
                    </div>
                    <div class="item text-center">
                      <div class="item-name">Кількість:</div>
                      <div class="value">{{ item.count }}</div>
                    </div>
                    <div class="item text-right">
                      <div class="item-name">Сума:</div>
                      <div class="value">{{ item.price * item.count }} грн</div>
                    </div>
                  </div>
                </div>
                <!-- end order product item-->

                <!-- order list item actions-->
                <div class="personal-order-list-item-bottom">
                  <div class="lines">
                    <div class="delivery">
                      <div class="name">Доставка:</div>
                      <div class="value">
                        <span class="price-element" v-if="order.total < minPrice">від 50 грн</span>
                        <span class="price-element" v-else>Безкоштовно</span>
                      </div>
                    </div>
                    <div class="total">
                      <div class="name">Всього:</div>
                      <div class="value">
                        <div class="price-element">{{ order.total }} грн</div>
                      </div>
                    </div>
                  </div>
                  <div class="button-holder">
                    <button type="button" class="default-btn btn-no-icon" @click="reorder(order)">
                      <span class="text">Повторити замовлення</span>
                    </button>
                  </div>
                </div>
                <!--end  order list item actions-->
              </div>
            </div>
          </div>
        </div>
        <!-- end full information about order-->
      </div>
      <!--end personal area order list item-->

    </div>
    <div v-else>
      Немає замовлень
    </div>
  </div>
</template>

<script>
export default {
  name: 'CabinetHistory',
  data() {
    return {
      list    : [],
      payment : [],
      delivery: [],
      minPrice: 1500
    }
  },
  mounted() {
    this.getHistory()
    window.$('.personal_area-sidebar').removeClass('open');
    window.$('body').removeClass('no-scroll');
    window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
  },
  computed: {
    payments() {
      return this.$store.getters.getPayment
    }
  },
  methods : {
    getOrderStatus(order) {
      switch (order.status) {
      case 'cancel':
        return 'order_canceled'
      case 'back':
        return 'order_canceled'
      case 'progress':
        return 'order_send'
      default:
        return 'order_success'
      }
    },
    reorder(order) {
      let busked = order.info.busked;
      this.$store.commit('setBusked', busked.map(i => {
        i.additionSelect  = []
        i.additionUSelect = []
        return i
      }));
      this.$router.push({name: 'Order'})
    },
    getPayName(order) {
      if (order?.info?.pay_type) {
        let x= this.payments.find(i => i.id === order.info.pay_type)
        if (x?.manifest?.title){
          return x.manifest.title
        }
      }
      return ''
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    buskedPreview(order) {
      return order.info.busked.slice(0, 2)
    },
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    getHistory() {
      let ok        = false
      let userInfo  = localStorage.getItem('user_info')
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true
      }
      let body = {}
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        ok        = true
      }
      if (!ok) {
        console.log('no auth data')
        return
      }
      return fetch(
          '/public/history',
          {
            method : 'POST',
            body   : JSON.stringify(body),
            headers: headers
          }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.list = json.result
        }
        if (json?.error){
          window.localStorage.removeItem('user_info')
          this.$router.push({'name':"login"})
        }
      })
    }
  }

}
</script>

<style scoped>

</style>