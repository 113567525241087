<template>
  <div class="custom-option-group" v-if="info && fullList.length" :aria-label="$updateLang()">
    <div class="custom-label">{{$dynamic(set, set.option, 'option')}}</div>
    <input type="checkbox" class="show-more" :id="'col'+option_key" v-model="open" style="display: none">
    <div class="custom-option-list">
      <div class="custom-option-checkbox check-image-full" v-for="(item, key) in fullList" :key="key">
        <input :id="'opt'+option_key+key" type="checkbox" :value="item" @change="event => onSelect(event, item)">
        <label :for="'opt'+option_key+key">
          <span class="pick" v-if="item.image" :style="getImage(item)"></span>
          <span class="pick" v-else-if="item.color" :style="getColor(item)"></span>
          <span class="text">
									<span class="custom-option-name" v-if="item.name">{{$dynamic(item, item.name, 'name')}}</span>
									<span class="custom-option-price">
                    <span class="price" v-if="item.price"> {{$filters.money(item.price)}} {{$store.getters.getSettings.symbol}}</span>
                    <span class="price price-old" v-if="item.price_old"> {{$filters.money(item.price_old)}} {{$store.getters.getSettings.symbol}}</span>
                  </span>
								</span>
        </label>
      </div>
    </div>
    <label :for="'col'+option_key" v-if="fullList.length > 4 && !open" class="show-more-label">
      <span class="text">{{$static('item.show-all', 'Показати всі')}}</span>
    </label>
  </div>
</template>

<script>
  export default {
    name    : 'ItemOption',
    props   : ['option', 'option_key', 'settings'],
    emits   : ['select'],
    data() {
      return {
        open: false
      }
    },
    methods : {
      getImage(item) {
        return `background-image: url(${item.image});`
      },
      getColor(item) {
        return `background-color: ${item.color};`
      },
      onSelect(event, item) {
        this.$emit('select', {key: this.option_key, item: item, status: event.target.checked})
      }
    },
    computed: {
      set(){
        return this.settings.find(x => x.option_id === this.option_key)
      },
      options_list() {
        return this.$store.getters.getOptions
      },
      info() {
        return this.option_key && this.options_list ? this.options_list[this.option_key] : undefined
      },
      fullList() {
        return this.info && this.option ? this.option.map(id => this.info.list.find(x => x.id == id)).filter(i => i) : []
      }
    }
  }
</script>
<style>
  .custom-option-list:before {
    z-index    : 1;
    content    : "";
    display    : none;
    width      : 100%;
    left       : 0;
    position   : absolute;
    bottom     : 0;
    height     : 35px;
    background : linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    background : -webkit-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }

  .custom-option-list {
    max-height : 107px;
    overflow   : hidden;
    position   : relative;
  }

  input[type='checkbox'].show-more:checked + div:before {
    display : none;
  }

  input[type='checkbox'].show-more:checked + div {
    max-height : inherit;
  }

  @media (min-width : 768px) {
    .custom-option-list {
      max-height : 121px;
    }
  }

  @media (min-width : 1200px) {
    .custom-option-list {
      max-height : 121px;
    }
  }
</style>